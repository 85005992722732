import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Wallet,
  ArrowUpRight,
  Shield,
  BadgeCheck,
  Bitcoin,
  DollarSign,
  Globe2,
  LineChart,
  TrendingUp,
  Clock,
  Users,
  ChevronRight,
  Sparkles
} from 'lucide-react';

const AnimatedTradingChart = () => (
  <svg viewBox="0 0 300 200" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <radialGradient id="glow" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
      <stop offset="0%" stopColor="rgba(234,179,8,0.2)"/>
      <stop offset="100%" stopColor="rgba(234,179,8,0)"/>
    </radialGradient>
    
    <filter id="dropShadow" x="-20%" y="-20%" width="140%" height="140%">
      <feGaussianBlur stdDeviation="1" result="blur"/>
      <feComposite in="SourceGraphic" in2="blur" operator="over"/>
    </filter>
  </defs>
  <circle cx="150" cy="100" r="80" fill="url(#glow)">
    <animate
      attributeName="r"
      values="80;90;80"
      dur="4s"
      repeatCount="indefinite"
    />
  </circle>
  <pattern id="smallGrid" width="10" height="10" patternUnits="userSpaceOnUse">
    <path d="M 10 0 L 0 0 0 10" fill="none" stroke="rgba(234,179,8,0.1)" strokeWidth="0.5"/>
  </pattern>
  <rect width="100%" height="100%" fill="url(#smallGrid)"/>
  <g filter="url(#dropShadow)">
    <g transform="translate(50,70)">
      <animateTransform
        attributeName="transform"
        type="translate"
        values="50,70;50,65;50,70"
        dur="3s"
        repeatCount="indefinite"
      />
      <rect x="0" y="0" width="60" height="40" rx="4" fill="#1F2937" stroke="#EAB308" strokeWidth="1"/>
      <circle cx="15" cy="15" r="5" fill="#EAB308"/>
      <line x1="30" y1="15" x2="50" y2="15" stroke="#EAB308" strokeWidth="2"/>
      <line x1="10" y1="30" x2="50" y2="30" stroke="#4B5563" strokeWidth="2"/>
    </g>
    <g transform="translate(190,70)">
      <animateTransform
        attributeName="transform"
        type="translate"
        values="190,70;190,75;190,70"
        dur="4s"
        repeatCount="indefinite"
      />
      <rect x="0" y="0" width="60" height="40" rx="4" fill="#1F2937" stroke="#EAB308" strokeWidth="1"/>
      <circle cx="15" cy="15" r="5" fill="#EAB308"/>
      <line x1="30" y1="15" x2="50" y2="15" stroke="#EAB308" strokeWidth="2"/>
      <line x1="10" y1="30" x2="50" y2="30" stroke="#4B5563" strokeWidth="2"/>
    </g>
  </g>
  <g strokeWidth="1" stroke="#EAB308">
    <path d="M110 90 Q150 60 190 90" fill="none">
      <animate
        attributeName="d"
        values="M110 90 Q150 60 190 90;M110 90 Q150 120 190 90;M110 90 Q150 60 190 90"
        dur="5s"
        repeatCount="indefinite"
      />
    </path>
  </g>
  <g>
    <circle r="2" fill="#EAB308">
      <animate
        attributeName="cx"
        values="110;190"
        dur="3s"
        repeatCount="indefinite"
      />
      <animate
        attributeName="cy"
        values="90;90"
        dur="3s"
        repeatCount="indefinite"
      />
    </circle>
    <circle r="2" fill="#EAB308">
      <animate
        attributeName="cx"
        values="190;110"
        dur="3s"
        repeatCount="indefinite"
      />
      <animate
        attributeName="cy"
        values="90;90"
        dur="3s"
        repeatCount="indefinite"
      />
    </circle>
  </g>
  {[0, 1, 2].map((i) => (
    <circle
      key={i}
      cx="150"
      cy="100"
      r="20"
      fill="none"
      stroke="#EAB308"
      strokeWidth="1"
      opacity="0"
    >
      <animate
        attributeName="r"
        values="20;40"
        dur="3s"
        begin={`${i}s`}
        repeatCount="indefinite"
      />
      <animate
        attributeName="opacity"
        values="0.3;0"
        dur="3s"
        begin={`${i}s`}
        repeatCount="indefinite"
      />
    </circle>
  ))}
</svg>
);
const LivePrice = () => {
  const [price, setPrice] = useState(0);
  const [trend, setTrend] = useState(0);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await fetch('https://api.coindesk.com/v1/bpi/currentprice.json');
        const data = await response.json();
        const newPrice = parseFloat(data.bpi.USD.rate.replace(',', ''));
        setPrice(prevPrice => {
          setTrend(newPrice > prevPrice ? 1 : -1);
          return newPrice;
        });
      } catch (error) {
        console.error('Error fetching Bitcoin price:', error);
      }
    };

    fetchPrice(); // Fetch immediately on mount
    const interval = setInterval(fetchPrice, 60000); // Then fetch every minute

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex items-center space-x-2">
      <Bitcoin className="h-5 w-5 sm:h-6 sm:w-6 text-yellow-500" />
      <span className={`text-sm sm:text-lg font-mono ${trend > 0 ? 'text-green-500' : trend < 0 ? 'text-red-500' : 'text-white'}`}>
        ${price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
      </span>
    </div>
  );
};

const StatsCard = ({ icon: Icon, title, value, trend }) => (
  <div className="bg-gray-800 border border-gray-700 hover:border-yellow-500/50 rounded-lg p-6 transition-all duration-300">
    <div className="flex items-center justify-between mb-2">
      <h3 className="text-sm font-medium text-gray-400">{title}</h3>
      <Icon className="h-4 w-4 text-yellow-500" />
    </div>
    <div className="text-2xl font-bold text-white">{value}</div>
    <p className={`text-sm ${trend > 0 ? 'text-green-500' : 'text-red-500'}`}>
      {trend > 0 ? '+' : ''}{trend}% from last month
    </p>
  </div>
);

const FeatureCard = ({ Icon, title, description, isHovered, onHover, onLeave }) => (
  <div
    className="bg-gray-800 p-6 sm:p-8 rounded-xl transform transition-all duration-300 hover:-translate-y-2 hover:shadow-2xl hover:shadow-yellow-500/10"
    onMouseEnter={onHover}
    onMouseLeave={onLeave}
  >
    <Icon className={`h-10 w-10 sm:h-12 sm:w-12 mb-4 transition-colors duration-300 ${isHovered ? 'text-yellow-400' : 'text-yellow-500'}`} />
    <h3 className="text-lg sm:text-xl font-bold mb-4 text-white">{title}</h3>
    <p className="text-gray-400">{description}</p>
    <ChevronRight className={`mt-4 transition-all duration-300 ${isHovered ? 'translate-x-2 text-yellow-400' : 'text-gray-600'}`} />
  </div>
);

const LandingPage = () => {
  const [hoveredFeature, setHoveredFeature] = useState(null);

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      {/* Header */}
      <header className="fixed w-full top-0 bg-gray-900/95 backdrop-blur-sm border-b border-gray-800 z-50">
        <div className="container mx-auto px-4 h-16 flex items-center justify-between">
          <div className="flex items-center gap-2 sm:gap-3">
            <div className="relative group">
              <div className="absolute -inset-0.5 bg-gradient-to-r from-yellow-500 to-yellow-600 rounded-full blur opacity-30 group-hover:opacity-100 transition duration-1000"></div>
              <div className="relative flex items-center space-x-2 bg-gray-900 rounded-full px-2 sm:px-4 py-1.5 sm:py-2">
                <Wallet className="h-5 w-5 sm:h-6 sm:w-6 text-yellow-500" />
                <span className="text-base sm:text-xl font-bold hidden xs:block">Nexiacoin</span>
              </div>
            </div>
            <LivePrice />
          </div>

          <div className="flex items-center gap-2 sm:gap-4">
      <Link to="/login">
        <button className="border border-yellow-600 text-yellow-500 hover:bg-yellow-600 hover:text-white px-3 py-1 h-8 sm:h-10 text-sm sm:text-base rounded-md">
          Login
        </button>
      </Link>
      <Link to="/signup">
        <button className="bg-yellow-500 hover:bg-yellow-600 text-gray-900 px-3 py-1 h-8 sm:h-10 text-sm sm:text-base rounded-md">
          Sign Up
        </button>
      </Link>
    </div>
        </div>
      </header>

      {/* Hero Section */}
      <section className="pt-24 sm:pt-32 pb-20 relative overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="absolute top-20 left-1/4 w-72 h-72 bg-yellow-500/20 rounded-full blur-3xl animate-pulse"></div>
          <div className="absolute top-40 right-1/4 w-96 h-96 bg-yellow-600/10 rounded-full blur-3xl animate-pulse" style={{ animationDelay: '1s' }}></div>
        </div>

        <div className="container mx-auto px-4 relative">


          <div className="grid lg:grid-cols-2 gap-8 sm:gap-12 items-center">
            <div className="space-y-6 sm:space-y-8">
              <div className="inline-flex items-center px-4 py-2 bg-yellow-500/10 text-yellow-500 rounded-full">
                <span className="mr-2">🚀</span> New: Zero-fee trading for 30 days
              </div>
              <h1 className="text-4xl sm:text-5xl font-bold leading-tight">
                Trade Crypto with
                <span className="text-yellow-500 block"> Confidence</span>
              </h1>
              <p className="text-lg sm:text-xl text-gray-400">
                Welcome to Nexiacoin - Your trusted platform for cryptocurrency trading with industry-best offers and minimal fees.
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
      <Link to="/login">
        <button className="bg-yellow-500 hover:bg-yellow-600 text-gray-900 text-sm sm:text-base px-4 sm:px-6 py-3 sm:py-4 rounded-md flex items-center justify-center group">
          Start Trading
          <ArrowUpRight className="ml-2 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
        </button>
      </Link>
      <Link to="/login">
        <button className="border border-yellow-600 text-yellow-500 hover:bg-yellow-600 hover:text-white text-sm sm:text-base px-4 sm:px-6 py-3 sm:py-4 rounded-md">
          View Markets
        </button>
      </Link>
    </div>
            </div>

            {/* Desktop Trading Chart Animation */}
            <div className="hidden lg:block relative h-[400px]">
              <div className="absolute inset-0 bg-gray-800/50 backdrop-blur-sm rounded-2xl border border-gray-700/50 shadow-xl">
                <AnimatedTradingChart />
              </div>
            </div>

            {/* Mobile Feature Cards */}
            <div className="lg:hidden relative">
              <div className="absolute -z-10 w-72 h-72 bg-yellow-500/20 rounded-full blur-3xl"></div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {[
                  {
                    Icon: Bitcoin,
                    title: "$25 BTC Bonus",
                    description: "Get free Bitcoin when you sign up and complete verification"
                  },
                  {
                    Icon: DollarSign,
                    title: "Low Fees",
                    description: "Trade with the lowest fees in the industry"
                  }
                ].map((item, index) => (
                  <div key={index} className="bg-gray-800 border border-gray-700 hover:border-yellow-500/50 p-6 rounded-lg transition-all duration-300 transform hover:-translate-y-1">
                    <item.Icon className="h-10 w-10 sm:h-12 sm:w-12 text-yellow-500 mb-4" />
                    <h3 className="text-xl font-bold mb-2">{item.title}</h3>
                    <p className="text-gray-400">{item.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* Stats Section */}
      <section className="py-16 sm:py-20 bg-gray-800/50">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 sm:gap-6">
            <StatsCard icon={Users} title="Active Traders" value="530k+" trend={12} />
            <StatsCard icon={TrendingUp} title="24h Volume" value="$8.2M" trend={8} />
            <StatsCard icon={Globe2} title="Countries" value="180+" trend={5} />
            <StatsCard icon={Clock} title="Uptime" value="99.99%" trend={0.1} />
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16 sm:py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold text-center mb-8 sm:mb-12">Why Choose Nexiacoin</h2>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
            {[
              {
                Icon: Shield,
                title: "Secure Platform",
                description: "Advanced encryption and multi-factor authentication to protect your assets"
              },
              {
                Icon: Globe2,
                title: "Global Access",
                description: "Trade from anywhere in the world with our robust platform"
              },
              {
                Icon: LineChart,
                title: "Advanced Trading",
                description: "Professional trading tools and real-time market data"
              }
            ].map((feature, index) => (
              <FeatureCard
                key={index}
                Icon={feature.Icon}
                title={feature.title}
                description={feature.description}
                isHovered={hoveredFeature === index}
                onHover={() => setHoveredFeature(index)}
                onLeave={() => setHoveredFeature(null)}
              />
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 sm:py-20">
        <div className="container mx-auto px-4 text-center">
          <div className="bg-gray-800 rounded-2xl p-8 sm:p-12 relative overflow-hidden group">
            <div className="absolute -z-10 w-96 h-96 bg-yellow-500/10 rounded-full blur-3xl -top-48 -right-48 group-hover:bg-yellow-500/20 transition-colors duration-300"></div>
            <BadgeCheck className="h-12 w-12 sm:h-16 sm:w-16 text-yellow-500 mx-auto mb-6 animate-bounce" />
            <h2 className="text-2xl sm:text-3xl font-bold mb-4">Start Trading Today</h2>
            <p className="text-lg sm:text-xl text-gray-400 mb-8 max-w-2xl mx-auto">
              Join thousands of traders worldwide and experience the future of cryptocurrency trading with Nexiacoin.
            </p>
            <Link to="/signup" className="bg-yellow-500 hover:bg-yellow-600 text-gray-900 text-base sm:text-lg px-6 sm:px-8 py-4 sm:py-6 rounded-md inline-flex items-center group">
      Create Free Account
      <ArrowUpRight className="ml-2 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
    </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;