import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowRightLeft, 
  Wallet, 
  ArrowDown, 
  Bitcoin,
  DollarSign,
  PiggyBank,
  ArrowLeft
} from 'lucide-react';

const CryptoConverter = () => {
  const [balance] = useState(0); // Simulated zero balance
  const [fromAmount, setFromAmount] = useState('');
  const [fromCurrency, setFromCurrency] = useState('BTC');
  const [toCurrency, setToCurrency] = useState('USDT');

  const currencies = ['BTC', 'ETH', 'USDT', 'BNB', 'XRP'];

  const handleSwap = () => {
    const temp = fromCurrency;
    setFromCurrency(toCurrency);
    setToCurrency(temp);
  };

  const handleBack = () => {
    // In a real app, this would use router navigation
    // For now, we'll just use window.history
    window.history.back();
  };
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/deposit');
  };
  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 p-4">
      {/* Back Button */}
      <button 
        onClick={handleBack}
        className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors mb-6 ml-4 lg:ml-8"
      >
        <ArrowLeft className="w-5 h-5" />
        <span>Back</span>
      </button>

      {/* Header Section */}
      <div className="max-w-2xl mx-auto pt-2 pb-12">
        <div className="flex items-center justify-center gap-3 mb-2">
          <ArrowRightLeft className="w-8 h-8 text-yellow-500" />
          <h1 className="text-3xl font-bold">Crypto Converter</h1>
        </div>
        <p className="text-center text-gray-400">
          Instantly convert your crypto assets at the best rates
        </p>
      </div>

      {/* Main Container */}
      <div className="max-w-xl mx-auto bg-gray-800 rounded-xl p-6 shadow-lg">
        {balance === 0 ? (
          // Zero Balance State
          <div className="text-center py-8">
            <PiggyBank className="w-16 h-16 mx-auto mb-4 text-gray-500" />
            <h2 className="text-xl font-semibold mb-3">You have no assets to convert</h2>
            <p className="text-gray-400 mb-6">Deposit funds to start trading</p>
            <button
      onClick={handleButtonClick}
      className="bg-yellow-600 hover:bg-yellow-700 text-white px-8 py-3 rounded-lg font-medium transition-colors flex items-center gap-2 mx-auto"
    >
      <Wallet className="w-5 h-5" />
      Deposit Now
    </button>
          </div>
        ) : (
          // Converter Interface
          <div className="space-y-6">
            {/* From Currency */}
            <div className="bg-gray-700 p-4 rounded-lg">
              <label className="text-sm text-gray-400 mb-2 block">From</label>
              <div className="flex gap-4">
                <select 
                  value={fromCurrency}
                  onChange={(e) => setFromCurrency(e.target.value)}
                  className="bg-gray-800 text-white px-3 py-2 rounded-lg w-32"
                >
                  {currencies.map(currency => (
                    <option key={currency} value={currency}>{currency}</option>
                  ))}
                </select>
                <input
                  type="number"
                  value={fromAmount}
                  onChange={(e) => setFromAmount(e.target.value)}
                  placeholder="0.00"
                  className="bg-gray-800 text-white px-4 py-2 rounded-lg flex-1"
                />
              </div>
            </div>

            {/* Swap Button */}
            <button 
              onClick={handleSwap}
              className="mx-auto block bg-gray-700 p-2 rounded-full hover:bg-gray-600 transition-colors"
            >
              <ArrowDown className="w-6 h-6" />
            </button>

            {/* To Currency */}
            <div className="bg-gray-700 p-4 rounded-lg">
              <label className="text-sm text-gray-400 mb-2 block">To</label>
              <div className="flex gap-4">
                <select 
                  value={toCurrency}
                  onChange={(e) => setToCurrency(e.target.value)}
                  className="bg-gray-800 text-white px-3 py-2 rounded-lg w-32"
                >
                  {currencies.map(currency => (
                    <option key={currency} value={currency}>{currency}</option>
                  ))}
                </select>
                <input
                  type="number"
                  placeholder="0.00"
                  disabled
                  className="bg-gray-800 text-white px-4 py-2 rounded-lg flex-1"
                  value={fromAmount ? (parseFloat(fromAmount) * 1.5).toFixed(2) : ''} // Simulated conversion rate
                />
              </div>
            </div>

            {/* Convert Button */}
            <button className="w-full bg-yellow-600 hover:bg-yellow-700 text-white py-3 rounded-lg font-medium transition-colors flex items-center justify-center gap-2">
              <ArrowRightLeft className="w-5 h-5" />
              Convert Now
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CryptoConverter;