import React, { useState } from 'react';
import { 
  Calendar,
  Clock,
  MapPin,
  ChevronRight,
  ChevronLeft,
  Star,
  Users,
  Search
} from 'lucide-react';

const CryptoEvents = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [searchQuery, setSearchQuery] = useState('');

  // Sample events data
  const events = [
    {
      id: 1,
      title: "Bitcoin Miami Conference",
      date: "2024-12-15",
      time: "09:00 AM",
      location: "Miami, FL",
      type: "Conference",
      featured: true
    },
    {
      id: 2,
      title: "Ethereum Developer Summit",
      date: "2024-12-20",
      time: "10:00 AM",
      location: "Virtual",
      type: "Summit",
      featured: false
    },
    {
      id: 3,
      title: "Crypto Trading Workshop",
      date: "2024-12-25",
      time: "02:00 PM",
      location: "London, UK",
      type: "Workshop",
      featured: true
    }
  ];

  const filteredEvents = events.filter(event => 
    event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    event.location.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const nextMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1));
  };

  const prevMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1));
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 p-4 md:p-8">
      {/* Header Section */}
      <header className="mb-8">
        <h1 className="text-3xl md:text-4xl font-bold mb-2">Crypto Events Calendar</h1>
        <p className="text-gray-400">Discover upcoming cryptocurrency events, conferences, and meetups</p>
      </header>

      {/* Search Bar */}
      <div className="relative mb-8">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        <input
          type="text"
          placeholder="Search events..."
          className="w-full bg-gray-800 text-gray-100 pl-10 pr-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-900"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* Calendar Navigation */}
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-4">
          <Calendar size={24} className="text-yellow-600" />
          <h2 className="text-xl font-semibold">
            {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
          </h2>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={prevMonth}
            className="p-2 bg-gray-800 rounded-lg hover:bg-gray-700"
          >
            <ChevronLeft size={20} />
          </button>
          <button
            onClick={nextMonth}
            className="p-2 bg-gray-800 rounded-lg hover:bg-gray-700"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      </div>

      {/* Featured Events */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4 flex items-center">
          <Star className="text-yellow-600 mr-2" size={20} />
          Featured Events
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredEvents
            .filter(event => event.featured)
            .map(event => (
              <div
                key={event.id}
                className="bg-gray-800 rounded-lg p-4 hover:bg-gray-700 transition-colors"
              >
                <div className="flex items-center justify-between mb-2">
                  <span className="text-yellow-600 font-semibold">{event.type}</span>
                  <Star size={16} className="text-yellow-600" fill="currentColor" />
                </div>
                <h4 className="text-lg font-semibold mb-2">{event.title}</h4>
                <div className="space-y-2 text-gray-400">
                  <div className="flex items-center">
                    <Calendar size={16} className="mr-2" />
                    {event.date}
                  </div>
                  <div className="flex items-center">
                    <Clock size={16} className="mr-2" />
                    {event.time}
                  </div>
                  <div className="flex items-center">
                    <MapPin size={16} className="mr-2" />
                    {event.location}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* All Events */}
      <div>
        <h3 className="text-xl font-semibold mb-4 flex items-center">
          <Users className="text-yellow-600 mr-2" size={20} />
          All Events
        </h3>
        <div className="space-y-4">
          {filteredEvents.map(event => (
            <div
              key={event.id}
              className="bg-gray-800 rounded-lg p-4 hover:bg-gray-700 transition-colors"
            >
              <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                <div className="mb-4 md:mb-0">
                  <h4 className="text-lg font-semibold">{event.title}</h4>
                  <span className="text-yellow-600 text-sm">{event.type}</span>
                </div>
                <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-6 text-gray-400">
                  <div className="flex items-center">
                    <Calendar size={16} className="mr-2" />
                    {event.date}
                  </div>
                  <div className="flex items-center">
                    <Clock size={16} className="mr-2" />
                    {event.time}
                  </div>
                  <div className="flex items-center">
                    <MapPin size={16} className="mr-2" />
                    {event.location}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CryptoEvents;