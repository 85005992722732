import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCRhNAX59-Jg_hFOzzngZ9eslW_eml52Jk",
  authDomain: "crypto-59494.firebaseapp.com",
  databaseURL: "https://crypto-59494-default-rtdb.firebaseio.com",
  projectId: "crypto-59494",
  storageBucket: "crypto-59494.firebasestorage.app",
  messagingSenderId: "325526537115",
  appId: "1:325526537115:web:6fb711de2c5f686892173e",
  measurementId: "G-9QDRG4Z8JF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const analytics = getAnalytics(app);

export { app, auth, database, analytics };