import React, { useState, useEffect } from 'react';
import { Bell, X, AlertCircle, Check, Archive, Trash2, ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { database } from './firebase';
import { ref, onValue, off, update, remove } from 'firebase/database';
import { getAuth } from 'firebase/auth';

const NotificationSkeleton = () => (
  <div className="animate-pulse space-y-3">
    <div className="flex gap-4 items-center">
      <div className="h-12 w-12 bg-gray-700 rounded-full" />
      <div className="space-y-2 flex-1">
        <div className="h-4 w-3/4 bg-gray-700 rounded" />
        <div className="h-4 w-1/2 bg-gray-700 rounded" />
      </div>
    </div>
  </div>
);

const NotificationSystem = ({ isModal = false, onClose }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('all');
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const notificationsRef = ref(database, 'notifications');
    
    const unsubscribe = onValue(notificationsRef, (snapshot) => {
      setLoading(true);
      try {
        if (snapshot.exists()) {
          const notificationsArray = Object.entries(snapshot.val()).map(([id, notification]) => ({
            id,
            ...notification,
          }))
          // Sort notifications by timestamp (newest first)
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
          setNotifications(notificationsArray);
        } else {
          setNotifications([]);
        }
      } catch (err) {
        setError(`Error processing notifications: ${err.message}`);
      } finally {
        setLoading(false);
      }
    }, (error) => {
      setError(`Database error: ${error.message}`);
      setLoading(false);
    });

    return () => {
      off(notificationsRef);
    };
  }, []);

  // Only allow modifications if user is authenticated
  const handleMarkAsRead = async (id) => {
    if (!auth.currentUser) {
      setError('Please log in to mark notifications as read');
      return;
    }

    try {
      const notificationRef = ref(database, `notifications/${id}`);
      await update(notificationRef, { read: true });
    } catch (err) {
      setError(`Failed to mark notification as read: ${err.message}`);
    }
  };

  const handleArchive = async (id) => {
    if (!auth.currentUser) {
      setError('Please log in to archive notifications');
      return;
    }

    try {
      const notificationRef = ref(database, `notifications/${id}`);
      await update(notificationRef, { archived: true });
    } catch (err) {
      setError(`Failed to archive notification: ${err.message}`);
    }
  };

  const handleDelete = async (id) => {
    if (!auth.currentUser) {
      setError('Please log in to delete notifications');
      return;
    }

    try {
      const notificationRef = ref(database, `notifications/${id}`);
      await remove(notificationRef);
    } catch (err) {
      setError(`Failed to delete notification: ${err.message}`);
    }
  };

  

  const filteredNotifications = notifications.filter(n => {
    if (activeTab === 'unread') return !n.read && !n.archived;
    if (activeTab === 'archived') return n.archived;
    return !n.archived;
  });

  return (
    <div className="bg-gray-900 text-gray-100">
      {error && (
        <div className="m-4 flex items-center gap-2 p-4 bg-red-900/50 rounded-lg text-red-200">
          <AlertCircle className="w-5 h-5" />
          {error}
          <button 
            onClick={() => setError(null)} 
            className="ml-auto hover:text-red-100"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      )}

      <div className="p-4 space-y-8">
        <div>
          <div className="flex items-center gap-2 mb-4">
            <Bell className="w-5 h-5" />
            <h2 className="text-xl font-semibold">Notifications</h2>
            {!loading && (
              <span className="text-sm text-gray-400">
                {filteredNotifications.length} items
              </span>
            )}
          </div>

          <div className="space-y-4">
            {loading ? (
              Array(3).fill(0).map((_, i) => (
                <div key={i} className="bg-gray-800 p-4 rounded-lg">
                  <NotificationSkeleton />
                </div>
              ))
            ) : filteredNotifications.length === 0 ? (
              <div className="text-center py-8 text-gray-400">
                No notifications to display
              </div>
            ) : (
              filteredNotifications.map((notification) => (
                <div
                  key={notification.id}
                  className={`p-4 rounded-lg transition-colors ${
                    notification.read ? 'bg-gray-800/50' : 'bg-gray-800'
                  }`}
                >
                  <div className="flex justify-between items-start gap-4">
                    <div className="flex-1">
                      <h3 className="font-medium">{notification.title}</h3>
                      <p className="text-gray-400 mt-1">{notification.message}</p>
                      <span className="text-sm text-gray-500 mt-2 block">
                        {notification.timestamp}
                      </span>
                    </div>
                    <div className="flex gap-2">
                      {!notification.read && auth.currentUser && (
                        <button
                          onClick={() => handleMarkAsRead(notification.id)}
                          className="p-2 hover:bg-gray-700 rounded-lg text-gray-400 hover:text-white transition-colors"
                          title="Mark as read"
                        >
                          <Check className="w-4 h-4" />
                        </button>
                      )}
                      {auth.currentUser && (
                        <>
                          <button
                            onClick={() => handleArchive(notification.id)}
                            className="p-2 hover:bg-gray-700 rounded-lg text-gray-400 hover:text-white transition-colors"
                            title="Archive"
                          >
                            <Archive className="w-4 h-4" />
                          </button>
                          <button
                            onClick={() => handleDelete(notification.id)}
                            className="p-2 hover:bg-gray-700 rounded-lg text-gray-400 hover:text-red-400 transition-colors"
                            title="Delete"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {isModal && (
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-300 hover:text-white">
          <X className="w-5 h-5" />
        </button>
      )}
      
      
    </div>
  );
};

export default NotificationSystem;