import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './header'; 

import {
  ArrowLeft,
  AlertCircle,
  ChevronDown,
  Loader,
  Copy,
  Check,
  X
} from 'lucide-react';

export default function DepositPage() {
  const [loading, setLoading] = useState(false);
  const [showAssetDropdown, setShowAssetDropdown] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [showNetworks, setShowNetworks] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();
  const addressInputRef = useRef(null);

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  const assets = [
    {
      name: 'Bitcoin',
      symbol: 'BTC',
      networks: [
        { name: 'Bitcoin Network', chain: 'BTC', fee: '0 BTC' },
      ],
      icon: '₿'
    },
    {
      name: 'Ethereum',
      symbol: 'ETH',
      networks: [
        { name: 'Ethereum (ERC20)', chain: 'ERC20', fee: '0 ETH' },
      ],
      icon: 'Ξ'
    },
    {
      name: 'USDT',
      symbol: 'USDT',
      networks: [
        { name: 'Tron Network', chain: 'TRC20', fee: '$0' },
      ],
      icon: '$'
    }
  ];

  const getWalletAddress = async (asset, network) => {
    setLoading(true);
    await new Promise(resolve => setTimeout(resolve, 1500));
    const addresses = {
      BTC: '1MQKcbyNtuEAk2mwG26VuqUgBCfA7tXYGS',
      ETH: '0xe18a3d521c33cecd95383d2721b9dbe23e847eff',
      USDT: 'TL8fjcPjdNzqHpptVH51csn8cuLj4k9k9Y'
    };
    setWalletAddress(addresses[asset.symbol]);
    setLoading(false);
  };

  const handleAssetSelect = (asset) => {
    setSelectedAsset(asset);
    setSelectedNetwork(asset.networks[0]);
    setShowAssetDropdown(false);
    getWalletAddress(asset, asset.networks[0]);
  };

  const handleNetworkSelect = (network) => {
    setSelectedNetwork(network);
    setShowNetworks(false);
    getWalletAddress(selectedAsset, network);
  };

  const handleCopy = () => {
    if (navigator.clipboard && window.isSecureContext) {
      // For modern browsers
      navigator.clipboard.writeText(walletAddress).then(() => {
        setIsCopied(true);
        setShowToast(true);
        setTimeout(() => setIsCopied(false), 2000);
      }).catch(err => console.error('Failed to copy:', err));
    } else {
      // Fallback for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = walletAddress;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        setIsCopied(true);
        setShowToast(true);
        setTimeout(() => setIsCopied(false), 2000);
      } catch (err) {
        console.error('Failed to copy:', err);
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <div className="pt-16 min-h-screen bg-black text-gray-100 relative">
      <Header/>
      {showToast && (
        <div className="fixed top-4 right-4 flex items-center gap-2 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg z-50 animate-fade-in">
          <Check size={16} className="text-green-500" />
          <span>Wallet address copied to clipboard!</span>
          <button 
            onClick={() => setShowToast(false)}
            className="ml-2 hover:text-gray-300"
          >
            <X size={16} />
          </button>
        </div>
      )}

      <div className="max-w-4xl mx-auto px-4">
        <div className="flex items-center h-16">
          <button
            className="text-gray-400 hover:text-gray-100 mr-4"
            onClick={() => navigate('/homepage')}
          >
            <ArrowLeft size={24} />
          </button>
          <h1 className="text-xl font-bold">Deposit Crypto</h1>
        </div>
      </div>

      <main className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-gray-900 rounded-xl border border-gray-800 p-6 flex flex-col md:flex-row">
          <div className="hidden md:block w-full md:w-1/3 p-4">
            <h2 className="text-lg font-bold mb-4">Instructions</h2>
            <ol className="list-decimal list-inside text-gray-400 space-y-2">
              <li>Select the asset you want to deposit.</li>
              <li>Choose the appropriate network.</li>
              <li>Copy the deposit address provided.</li>
              <li>Send the crypto from your wallet to the address.</li>
              <li>Check transaction details for confirmation.</li>
            </ol>
          </div>

          <div className="w-full md:w-2/3 space-y-8">
            <div className="bg-yellow-500/10 border border-yellow-500/20 rounded-lg p-4">
              <div className="flex items-start space-x-3">
                <AlertCircle size={20} className="text-yellow-500 flex-shrink-0 mt-0.5" />
                <div>
                  <h4 className="font-medium text-yellow-500">Good news!</h4>
                  <p className="text-sm text-gray-400">
                    We have covered the transaction fees for first deposits!
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-8">
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Select Asset
              </label>
              <div className="relative">
                <button
                  className="w-full flex items-center justify-between bg-gray-800 rounded-lg p-4 hover:bg-gray-700 transition-colors"
                  onClick={() => setShowAssetDropdown(!showAssetDropdown)}
                >
                  {selectedAsset ? (
                    <div className="flex items-center space-x-3">
                      <div className="w-8 h-8 bg-yellow-500 rounded-full flex items-center justify-center text-black font-bold">
                        {selectedAsset.icon}
                      </div>
                      <div>
                        <span className="font-medium">{selectedAsset.name}</span>
                        <span className="text-gray-400 ml-2">({selectedAsset.symbol})</span>
                      </div>
                    </div>
                  ) : (
                    <span className="text-gray-400">Select an asset to deposit</span>
                  )}
                  <ChevronDown size={20} className="text-gray-400" />
                </button>

                {showAssetDropdown && (
                  <div className="absolute w-full mt-2 bg-gray-800 rounded-lg shadow-lg border border-gray-700 py-2 z-10">
                    {assets.map((asset) => (
                      <button
                        key={asset.symbol}
                        className="w-full px-4 py-3 text-left hover:bg-gray-700 transition-colors flex items-center space-x-3"
                        onClick={() => handleAssetSelect(asset)}
                      >
                        <div className="w-8 h-8 bg-yellow-500 rounded-full flex items-center justify-center text-black font-bold">
                          {asset.icon}
                        </div>
                        <div>
                          <span className="font-medium">{asset.name}</span>
                          <span className="text-gray-400 ml-2">({asset.symbol})</span>
                        </div>
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {selectedAsset && (
              <>
                <div className="mb-8">
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Select Network
                  </label>
                  <div className="relative">
                    <button
                      className="w-full flex items-center justify-between bg-gray-800 rounded-lg p-4 hover:bg-gray-700 transition-colors"
                      onClick={() => setShowNetworks(!showNetworks)}
                    >
                      {selectedNetwork ? (
                        <div>
                          <span className="font-medium">{selectedNetwork.name}</span>
                          <span className="text-gray-400 ml-2">Fee: {selectedNetwork.fee}</span>
                        </div>
                      ) : (
                        <span className="text-gray-400">Select a network</span>
                      )}
                      <ChevronDown size={20} className="text-gray-400" />
                    </button>

                    {showNetworks && (
                      <div className="absolute w-full mt-2 bg-gray-800 rounded-lg shadow-lg border border-gray-700 py-2 z-10">
                        {selectedAsset.networks.map((network) => (
                          <button
                            key={network.chain}
                            className="w-full px-4 py-3 text-left hover:bg-gray-700 transition-colors"
                            onClick={() => handleNetworkSelect(network)}
                          >
                            <div className="font-medium">{network.name}</div>
                            <div className="text-sm text-gray-400">Network Fee: {network.fee}</div>
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-8">
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Deposit Address
                  </label>
                  {loading ? (
                    <div className="bg-gray-800 rounded-lg p-8">
                      <div className="flex flex-col items-center justify-center space-y-4">
                        <Loader size={32} className="text-yellow-500 animate-spin" />
                        <p className="text-gray-400">Getting wallet address...</p>
                      </div>
                    </div>
                  ) : walletAddress ? (
                    <>
                      <div className="flex items-center space-x-4 mb-4">
                        <input
                          ref={addressInputRef}
                          type="text"
                          readOnly
                          value={walletAddress}
                          className="flex-grow bg-gray-800 rounded-lg p-4 font-mono text-sm break-all text-gray-100"
                        />
                        <button
                          className={`${
                            isCopied ? 'bg-green-500' : 'bg-yellow-500 hover:bg-yellow-400'
                          } text-black rounded-md p-2 flex items-center space-x-1 transition-colors duration-200`}
                          onClick={handleCopy}
                        >
                          {isCopied ? (
                            <Check size={16} />
                          ) : (
                            <Copy size={16} />
                          )}
                          <span className="text-sm font-medium">
                            {isCopied ? 'Copied!' : 'Copy'}
                          </span>
                        </button>
                      </div>

                      <div className="bg-yellow-500/10 border border-yellow-500/20 rounded-lg p-4">
                        <div className="flex items-start space-x-3">
                          <AlertCircle size={20} className="text-yellow-500 flex-shrink-0 mt-0.5" />
                          <div>
                            <h4 className="font-medium text-yellow-500">Important</h4>
                            <p className="text-sm text-gray-400">
                              Send only {selectedAsset.symbol} on {selectedNetwork.name}. Sending other assets or using incorrect networks may result in permanent loss.
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                {!loading && walletAddress && (
                  <div>
                    <h3 className="font-medium mb-4">Transaction Details</h3>
                    <div className="space-y-4">
                      <div className="flex justify-between py-2 border-b border-gray-800">
                        <span className="text-gray-400">Network</span>
                        <span>{selectedNetwork.name}</span>
                      </div>
                      <div className="flex justify-between py-2 border-b border-gray-800">
                        <span className="text-gray-400">Estimated Fee</span>
                        <span>{selectedNetwork.fee}</span>
                      </div>
                      <div className="flex justify-between py-2 border-b border-gray-800">
                        <span className="text-gray-400">Expected Arrival</span>
                        <span>10-30 minutes</span>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}