// header.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, database } from './firebase';
import { ref, onValue } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import NotificationSystem from './notifications';
import { Link } from 'react-router-dom';

import MoreHoriz from '@mui/icons-material/MoreHoriz';
import {
  ArrowUpRight,
  ArrowDownRight,
  Bell,
  X,
  LogOut,
  User,
  HelpCircle,
  setIsSidebarOpen,
  isSidebarOpen,
  Settings,
  Menu,
  ArrowDown,
  Send,
  ArrowRightLeft,
  RefreshCw,
  ChevronRight,
  Layout,
  LineChart,
  History,
  UserCircle,
  Wallet,
  TrendingUp,
  Landmark,
  BarChart2,
  Sun,
  Moon, Search, Copy, QrCode, ExternalLink,
  AlertCircle, ChevronDown, Loader,
  ArrowLeft, Info, Check
} from 'lucide-react';
const Sidebar = ({ isOpen, onClose, menuItems, userData, isLoading, }) => {
  const [openSubmenus, setOpenSubmenus] = useState({});
  const navigate = useNavigate();

  const toggleSubmenu = (menuName) => {
    setOpenSubmenus(prev => ({
      ...prev,
      [menuName]: !prev[menuName]
    }));
  };
  const handleSignInClick = () => {
    navigate('/login');
  };
  return (
    <>
      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black/60 backdrop-blur-sm transition-opacity z-40"
          onClick={onClose}
        />
      )}

      {/* Sidebar */}
      <div className={`fixed top-0 left-0 h-full w-80 bg-gradient-to-b from-gray-900 to-black transform transition-transform duration-300 ease-in-out z-50 ${isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}>
        {/* Header Section */}
        <div className="relative h-full flex flex-col">
          <div className="p-6 border-b border-gray-800">
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center">
                <div className="h-8 w-8 bg-yellow-400 rounded-lg flex items-center justify-center">
                  <span className="text-black font-bold text-xl">N</span>
                </div>
                <span className="ml-3 text-xl font-bold text-white">NexiaCoin</span>
              </div>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-white p-1 hover:bg-gray-800 rounded-lg transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            {/* User Info Section */}
            <div className="flex items-center p-3 bg-gray-800/50 rounded-lg">
              <div className="w-10 h-10 bg-gray-700 rounded-full flex items-center justify-center">
                <User className="w-5 h-5 text-gray-400" />
              </div>
              <div className="ml-3">
                {isLoading ? (
                  <div className="animate-pulse space-y-2">
                    <div className="h-4 w-20 bg-gray-700 rounded"></div>
                    <div className="h-3 w-24 bg-gray-700 rounded"></div>
                  </div>
                ) : userData ? (
                  <>
                    <p className="text-sm font-medium text-white">{userData.username}</p>
                    <p className="text-xs text-gray-400">
                      ID: {userData.uid.substring(0, 6)}...{userData.uid.slice(-4)}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="text-sm font-medium text-white cursor-pointer" onClick={() => navigate('/login')}>Sign In</p>
                    <p className="text-xs text-gray-400">Click to login</p>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Menu Items */}
          <div className="flex-1 overflow-y-auto py-4">
            <div className="px-4 mb-4">
              <p className="px-4 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                Main Menu
              </p>
            </div>

            {menuItems.map((item, index) => (
              <div key={index} className="mb-2 px-4">
                <button
                  onClick={() => toggleSubmenu(item.name)}
                  className={`w-full flex items-center justify-between p-3 rounded-lg transition-colors ${openSubmenus[item.name]
                      ? 'bg-yellow-400/10 text-yellow-400'
                      : 'text-gray-300 hover:bg-gray-800'
                    }`}
                >
                  <div className="flex items-center">
                    <item.icon className="w-5 h-5 mr-3" />
                    <span className="font-medium">{item.name}</span>
                  </div>
                  <ChevronRight className={`w-4 h-4 transition-transform ${openSubmenus[item.name] ? 'rotate-90' : ''
                    }`} />
                </button>

                {openSubmenus[item.name] && (
                  <div className="mt-2 ml-4 pl-4 border-l border-gray-800">
                    {item.submenu.map((subItem, subIndex) => (
                      <a
                        key={subIndex}
                        href={subItem.link}
                        className="flex items-center py-2 px-4 text-sm text-gray-400 hover:text-white hover:bg-gray-800/50 rounded-lg transition-colors"
                      >
                        {subItem.name}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Bottom Section */}
          <div className="border-t border-gray-800 p-4">
            <div className="space-y-2">
              <a href="/settings" className="flex items-center px-4 py-2 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg transition-colors">
                <Settings className="w-5 h-5 mr-3" />
                <span>Settings</span>
              </a>
              <a href="/helpcenter" className="flex items-center px-4 py-2 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg transition-colors">
                <HelpCircle className="w-5 h-5 mr-3" />
                <span>Help Center</span>
              </a>
              <a href="login" className="flex items-center px-4 py-2 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg transition-colors">
                <LogOut className="w-5 h-5 mr-3" />
                <span>Sign Out</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Header = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [openSubmenus, setOpenSubmenus] = useState({});
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [showNotifications, setShowNotifications] = useState(false);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const toggleSubmenu = (menuName) => {
    setOpenSubmenus(prev => ({
      ...prev,
      [menuName]: !prev[menuName]
    }));
  };

  const menuItems = [
    {
      name: 'Dashboard',
      icon: Layout,
      submenu: [
        { name: 'Home', link: '/homepage' },
        { name: 'Wallet', link: '/wallet' },
        { name: 'Withdraw', link: '/withdraw' },
        { name: 'Deposit', link: '/deposit' },
        { name: 'Convert', link: '/convert' },
      ]
    },
    {
      name: 'Tools',
      icon: LineChart,
      submenu: [
        { name: 'Copy Trading', link: '/copytrading' },
        { name: 'Loans', link: '/ordersloans' },
        { name: 'Lending/Borrowing', link: '/ordersloans' },
        { name: 'Earn', link: '/earncrypto' },
      ]
    },
    {
      name: 'Explore',
      icon: ArrowRightLeft,
      submenu: [
        { name: 'Blog', link: '/blog' },
        { name: 'Market News', link: '/marketnews' },
        { name: 'Community Forum', link: '/community' },
        { name: 'Event Calendar', link: '/events' }
      ]
    },
    {
      name: 'More',
      icon: MoreHoriz,
      submenu: [
        { name: 'Transactions', link: '/transactions' },
        { name: 'Orders', link: '/orders' },
        { name: 'Help & Support', link: '/helpcenter' },
        { name: 'Security', link: '/settings' },
        { name: 'Sign out', link: '/login' }
      ]
    }
  ];

  // Fixed dark theme styles
  const darkTheme = {
    background: 'bg-black',
    text: 'text-white',
    header: 'bg-black border-gray-800',
    dropdownBg: 'bg-gray-800',
    dropdownHover: 'hover:bg-gray-700',
    cardBg: 'bg-gray-800',
    cardBorder: 'border-gray-800',
    secondaryText: 'text-gray-400',
    buttonBg: 'bg-gray-800',
    buttonHover: 'hover:bg-gray-700',
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userRef = ref(database, `users/${user.uid}`);
        const unsubscribeDB = onValue(userRef, (snapshot) => {
          if (snapshot.exists()) {
            setUserData({
              uid: user.uid,
              username: snapshot.val().username,
              ...snapshot.val()
            });
          }
          setIsLoading(false);
        });

        return () => unsubscribeDB();
      } else {
        setUserData(null);
        setIsLoading(false);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    const protectedRoutes = ['/trade', '/wallet', '/settings'];
    if (!isLoading && !userData && protectedRoutes.includes(window.location.pathname)) {
      navigate('/login');
    }
  }, [userData, isLoading, navigate]);

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isSidebarOpen]);


  return (
    <div className={`h-auto ${darkTheme.text}`}>
    <header className={`fixed top-0 left-0 right-0 h-16 border-b z-50 ${darkTheme.header}`}>
      <div className="h-full max-w-7xl mx-auto px-4 lg:px-8">
        <div className="flex justify-between items-center h-full">
          <div className="flex items-center gap-8">
            <div className="flex items-center">
              <button
                onClick={() => setIsSidebarOpen(true)}
                className="mr-4 text-gray-400 hover:text-white md:hidden"
              >
                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
              <span className="text-xl font-bold">NexiaCoin</span>
            </div>

            <nav className="hidden lg:flex items-center gap-4">
              {menuItems.map((item) => {
                const Icon = item.icon;
                const toggleDropdown = () => {
                  setActiveDropdown((prev) => (prev === item.name ? null : item.name));
                };

                return (
                  <div
                    key={item.name}
                    className="relative"
                    onMouseEnter={() => setActiveDropdown(item.name)}
                  >
                    <button
                      onClick={toggleDropdown}
                      className={`flex items-center gap-2 px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                        activeDropdown === item.name
                          ? `${darkTheme.buttonBg} text-yellow-500`
                          : `${darkTheme.secondaryText} hover:text-current`
                      }`}
                    >
                      <Icon size={18} />
                      {item.name}
                      <ChevronDown size={14} />
                    </button>

                    {activeDropdown === item.name && (
                      <div className={`absolute top-full left-0 mt-1 w-48 rounded-md shadow-lg py-1 z-50 ${darkTheme.dropdownBg}`}>
                        {item.submenu.map((subItem) => (
                          <a
                            key={subItem.name}
                            href={subItem.link}
                            className={`block px-4 py-2 text-sm ${darkTheme.secondaryText} ${darkTheme.dropdownHover}`}
                          >
                            {subItem.name}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </nav>
          </div>

          <div className="flex items-center gap-6">
            <div className="hidden lg:flex items-center gap-4">
              {isLoading ? (
                <div className="animate-pulse bg-gray-700 h-8 w-24 rounded"></div>
              ) : userData ? (
                <div className="flex items-center gap-2">
                  <UserCircle className="w-5 h-5" />
                  <span className="text-sm font-medium">{userData.username}</span>
                </div>
              ) : (
                <button
                  onClick={() => navigate('/login')}
                  className="px-4 py-2 rounded-lg bg-yellow-500 text-black font-medium hover:bg-yellow-400"
                >
                  Sign In
                </button>
              )}
            </div>
            <div className="flex items-center gap-4">
              <Bell
                onClick={toggleNotifications}
                className="text-gray-600 hover:text-yellow-600 cursor-pointer"
              />

              {showNotifications && (
                <div className="fixed top-16 right-4 z-50 w-[500px] max-w-[calc(100vw-2rem)] max-h-[80vh] shadow-lg rounded-lg bg-gray-900 border border-gray-800 md:w-[500px] sm:w-[95vw] mobile:w-[95vw] mobile:right-0 mobile:left-0 mobile:mx-auto">
                  <div className="relative">
                    <button
                      onClick={() => setShowNotifications(false)}
                      className="absolute top-4 right-4 z-10 p-2 hover:bg-gray-800 rounded-lg"
                    >
                    </button>
                    <NotificationSystem />
                  </div>
                </div>
              )}

              <Link to="/settings">
                <Settings className={`${darkTheme.secondaryText} hover:text-yellow-600 cursor-pointer`} />
              </Link>
              <Link to="/settings">
                <UserCircle className={`${darkTheme.secondaryText} hover:text-yellow-600 cursor-pointer`} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>

    {isSidebarOpen && (
      <div className="fixed inset-0 bg-black/60 backdrop-blur-sm transition-opacity z-40" onClick={() => setIsSidebarOpen(false)} />
    )}
    <Sidebar
      isOpen={isSidebarOpen}
      onClose={() => setIsSidebarOpen(false)}
      menuItems={menuItems}
      userData={userData}
      isLoading={isLoading}
    />
  </div>
);
};

export default Header;
