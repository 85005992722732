import React, { useState, useEffect } from 'react';
import Header from './header'; 
import { database, auth } from './firebase';
import { ref, onValue } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import { CircleUserRound, Wallet, ArrowUpRight, ArrowDownRight, Clock, Settings, 
  Bell, Copy, Eye, EyeOff, ChevronRight, Shield, Key, UserCircle, Gift, 
  History, Star, TrendingUp, Boxes, Gem, CreditCard, Loader2, Mail } from 'lucide-react';

const MyWallet = () => {
  const [showBalance, setShowBalance] = useState(true);
  const [activeTab, setActiveTab] = useState('activity');
  const [activeSection, setActiveSection] = useState('spot');
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  const [orders, setOrders] = useState([]);
  const [positions, setPositions] = useState([]);
  const [balance, setBalance] = useState({ total: '0.00', change: '0.00' });
  const [loadingStates, setLoadingStates] = useState({
    activities: true,
    orders: true,
    positions: true
  });
  const [userInfo, setUserInfo] = useState({
    username: '',
    email: '',
    uid: '',
  });
  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Set basic user info from auth
        setUserInfo({
          username: '',
          email: user.email || '',
          uid: user.uid,
        });

        // Fetch additional user info from database
        const userRef = ref(database, `users/${user.uid}`);
        const unsubscribeUser = onValue(userRef, (snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setUserInfo(prevInfo => ({
              ...prevInfo,
              username: userData.username || 'User',
            }));
            setBalance({
              total: userData.balance?.total || '0.00',
              change: userData.balance?.change24h || '0.00'
            });
          }
          setIsLoading(false);
        });

        // Fetch activities
        const activitiesRef = ref(database, `users/${user.uid}/activities`);
        const unsubscribeActivities = onValue(activitiesRef, (snapshot) => {
          setLoadingStates(prev => ({ ...prev, activities: false }));
          if (snapshot.exists()) {
            const activitiesData = Object.values(snapshot.val());
            setActivities(activitiesData);
          } else {
            setActivities([]);
          }
        });

        // Fetch orders
        const ordersRef = ref(database, `users/${user.uid}/orders`);
        const unsubscribeOrders = onValue(ordersRef, (snapshot) => {
          setLoadingStates(prev => ({ ...prev, orders: false }));
          if (snapshot.exists()) {
            const ordersData = Object.values(snapshot.val());
            setOrders(ordersData);
          } else {
            setOrders([]);
          }
        });

        // Fetch positions
        const positionsRef = ref(database, `users/${user.uid}/positions`);
        const unsubscribePositions = onValue(positionsRef, (snapshot) => {
          setLoadingStates(prev => ({ ...prev, positions: false }));
          if (snapshot.exists()) {
            const positionsData = Object.values(snapshot.val());
            setPositions(positionsData);
          } else {
            setPositions([]);
          }
        });

        return () => {
          unsubscribeUser();
          unsubscribeActivities();
          unsubscribeOrders();
          unsubscribePositions();
        };
      } else {
        setUserInfo({
          username: '',
          email: '',
          uid: '',
        });
        setIsLoading(false);
        setLoadingStates({
          activities: false,
          orders: false,
          positions: false
        });
      }
    });

    return () => unsubscribeAuth();
  }, []);

  // Function to format UID for display
  const formatUID = (uid) => {
    if (!uid) return '';
    return `${uid.slice(0, 6)}...${uid.slice(-4)}`;
  };

  // Function to copy text to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    // You could add a toast notification here
  };

  const maskBalance = (value) => showBalance ? value : '****';

  const renderContent = () => {
    if (loadingStates[activeTab]) {
      return (
        <div className="flex items-center justify-center py-8">
          <Loader2 className="w-6 h-6 animate-spin text-yellow-500" />
        </div>
      );
    }

    switch (activeTab) {
      case 'activity':
        return activities.length > 0 ? (
          <div className="space-y-3">
            {activities.map((tx, index) => (
              <div key={index} className="flex items-center justify-between p-4 bg-gray-900/50 backdrop-blur-sm rounded-xl hover:bg-gray-800/50 transition-all duration-200 cursor-pointer border border-gray-800/50">
                <div className="flex items-center gap-3">
                  <div className={`p-2 rounded-xl ${tx.type === 'Received' ? 'bg-green-500/20' : 'bg-red-500/20'}`}>
                    {tx.type === 'Received' ? (
                      <ArrowDownRight className={`w-5 h-5 ${tx.type === 'Received' ? 'text-green-500' : 'text-red-500'}`} />
                    ) : (
                      <ArrowUpRight className={`w-5 h-5 ${tx.type === 'Received' ? 'text-green-500' : 'text-red-500'}`} />
                    )}
                  </div>
                  <div>
                    <p className="font-semibold text-white">{tx.type}</p>
                    <div className="flex items-center gap-2">
                      <p className="text-sm text-gray-400">{tx.address}</p>
                      <span className="text-xs px-2 py-1 bg-gray-800/50 rounded-lg text-gray-300">{tx.platform}</span>
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <p className="font-semibold text-white">{showBalance ? tx.amount : '****'}</p>
                  <div className="flex items-center gap-1 text-sm text-gray-400">
                    <Clock className="w-4 h-4" />
                    <span>{tx.date}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-8 bg-gray-900/50 backdrop-blur-sm rounded-xl border border-gray-800/50">
            <History className="w-12 h-12 text-gray-600 mx-auto mb-3" />
            <p className="text-gray-400">No recent activity</p>
          </div>
        );

      case 'orders':
        return orders.length > 0 ? (
          <div className="space-y-3">
            {orders.map((order, index) => (
              <div key={index} className="p-4 bg-gray-900/50 backdrop-blur-sm rounded-xl border border-gray-800/50">
                <div className="flex justify-between items-center">
                  <span className="text-sm font-medium text-gray-300">{order.pair}</span>
                  <span className={`text-sm ${order.side === 'buy' ? 'text-green-500' : 'text-red-500'}`}>
                    {order.side.toUpperCase()}
                  </span>
                </div>
                <div className="mt-2 flex justify-between text-sm text-gray-400">
                  <span>Price: {order.price}</span>
                  <span>Amount: {order.amount}</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-8 bg-gray-900/50 backdrop-blur-sm rounded-xl border border-gray-800/50">
            <Boxes className="w-12 h-12 text-gray-600 mx-auto mb-3" />
            <p className="text-gray-400">No open orders</p>
          </div>
        );

      case 'positions':
        return positions.length > 0 ? (
          <div className="space-y-3">
            {positions.map((position, index) => (
              <div key={index} className="p-4 bg-gray-900/50 backdrop-blur-sm rounded-xl border border-gray-800/50">
                <div className="flex justify-between items-center">
                  <span className="text-sm font-medium text-gray-300">{position.pair}</span>
                  <span className={`text-sm ${position.pnl >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                    {position.pnl}%
                  </span>
                </div>
                <div className="mt-2 flex justify-between text-sm text-gray-400">
                  <span>Size: {position.size}</span>
                  <span>Leverage: {position.leverage}x</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-8 bg-gray-900/50 backdrop-blur-sm rounded-xl border border-gray-800/50">
            <TrendingUp className="w-12 h-12 text-gray-600 mx-auto mb-3" />
            <p className="text-gray-400">No open positions</p>
          </div>
        );
    }
  };

  return (
    <div className="pt-16 min-h-screen bg-gradient-to-b from-black via-gray-900 to-black text-white overflow-y-auto scroll-smooth">
      <Header />
      
      {/* Profile Section */}
      <div className="px-4 md:px-8 py-6 sticky top-0 bg-gradient-to-b from-black to-transparent backdrop-blur-lg z-10">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <div className="relative">
              <div className="w-16 h-16 rounded-full bg-gradient-to-r from-yellow-500 to-yellow-600 flex items-center justify-center">
                <CircleUserRound className="w-12 h-12 text-white" />
              </div>
              <div className="absolute bottom-0 right-0 w-4 h-4 bg-green-500 rounded-full border-2 border-black"></div>
            </div>
            <div className="space-y-1">
              {isLoading ? (
                <Loader2 className="w-5 h-5 animate-spin text-yellow-500" />
              ) : (
                <>
                  <h2 className="text-xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                    {userInfo.username}
                  </h2>
                  <div className="flex items-center gap-2 text-gray-400">
                    <Mail className="w-4 h-4" />
                    <span className="text-sm">{userInfo.email}</span>
                  </div>
                  <div className="flex items-center gap-2 text-gray-400 group cursor-pointer"
                       onClick={() => copyToClipboard(userInfo.uid)}>
                    <Key className="w-4 h-4" />
                    <span className="text-sm font-mono">{formatUID(userInfo.uid)}</span>
                    <Copy className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity hover:text-yellow-500" />
                  </div>
                </>
              )}
            </div>
          </div>
          
          <div className="flex gap-2">
            <button className="px-4 py-2 rounded-xl bg-gray-800 hover:bg-gray-700 transition-colors text-sm flex items-center gap-2">
              <Shield className="w-4 h-4" />
              Security
            </button>
            <button className="px-4 py-2 rounded-xl bg-gray-800 hover:bg-gray-700 transition-colors text-sm flex items-center gap-2">
              <Settings className="w-4 h-4" />
              Edit my info
            </button>
          </div>
        </div>
      </div>

      <div className="px-4 md:px-8 py-6">
        {/* Balance Card */}
        <div className="mb-8 p-6 bg-gradient-to-r from-gray-900 to-gray-800 rounded-2xl border border-gray-800">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-sm font-medium text-gray-400">Total Balance</h3>
            <button 
              onClick={() => setShowBalance(!showBalance)}
              className="text-gray-400 hover:text-yellow-500 transition-colors"
            >
              {showBalance ? <EyeOff className="w-4 h-4" /> : <Eye className="w-4 h-4" />}
            </button>
          </div>
          <p className="text-2xl font-bold text-white mb-1">
            ${maskBalance(balance.total)}
          </p>
          <p className={`text-sm ${parseFloat(balance.change) >= 0 ? 'text-green-500' : 'text-red-500'}`}>
            {parseFloat(balance.change) >= 0 ? '+' : ''}{balance.change} (24h)
          </p>
        </div>

        {/* Section Tabs */}
        <div className="flex gap-4 mb-6 border-b border-gray-800 overflow-x-auto hide-scrollbar">
          {['Spot', 'Derivatives', 'Earn'].map((section) => (
            <button 
              key={section}
              className={`pb-2 px-1 text-sm whitespace-nowrap transition-colors ${
                activeSection === section.toLowerCase() 
                  ? 'text-yellow-500 border-b-2 border-yellow-500' 
                  : 'text-gray-400 hover:text-gray-300'
              }`}
              onClick={() => setActiveSection(section.toLowerCase())}
            >
              {section}
            </button>
          ))}
        </div>

        {/* Activity Tabs */}
        <div className="flex gap-2 mb-6 overflow-x-auto hide-scrollbar">
          {[
            { id: 'activity', label: 'Recent Activity', icon: History },
            { id: 'orders', label: 'Open Orders', icon: Boxes },
            { id: 'positions', label: 'Positions', icon: TrendingUp }
          ].map((tab) => (
            <button 
              key={tab.id}
              className={`px-4 py-2 rounded-xl text-sm whitespace-nowrap flex items-center gap-2 transition-all duration-200 ${
                activeTab === tab.id 
                  ? 'bg-yellow-500 text-black shadow-lg shadow-yellow-500/20' 
                  : 'text-gray-400 hover:bg-gray-800'
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              <tab.icon className="w-4 h-4" />
              {tab.label}
            </button>
          ))}
        </div>

        {/* Content Section */}
        {renderContent()}
      </div>

      <style jsx global>{`
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        html {
          scroll-behavior: smooth;
        }
      `}</style>
    </div>
  );
};

export default MyWallet;