import React, { useState, useEffect } from 'react';
import Header from './header';

import { 
  ArrowUpRight, 
  ArrowDownLeft, 
  DollarSign, 
  Clock,
  Search,
  Filter,
  Loader2,
} from 'lucide-react';

const TransactionsPage = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    try {
      setIsLoading(true);
      
      // Replace with your actual API endpoint
      const response = await fetch('YOUR_API_ENDPOINT/transactions');
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      setTransactions(data);
    } catch (err) {
      console.error('Error fetching transactions:', err);
      setTransactions([]); // Set empty array instead of error state
    } finally {
      setIsLoading(false);
    }
  };

  // Loading state
  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-900 text-gray-100 flex items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <Loader2 className="w-12 h-12 animate-spin text-amber-600" />
          <p className="text-lg">Loading transactions...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 p-4 md:p-8">
      <Header/>
      {/* Header Section */}
      <div className="max-w-6xl mx-auto">
        <h1 className="text-2xl md:text-3xl font-bold mb-8">Transactions</h1>

        {/* Search and Filter Bar */}
        <div className="flex flex-col md:flex-row gap-4 mb-6">
          <div className="relative flex-1">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search transactions..."
              className="w-full bg-gray-800 rounded-lg pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-amber-600"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button 
            className="flex items-center justify-center gap-2 bg-amber-600 hover:bg-amber-700 px-4 py-2 rounded-lg transition-colors"
            onClick={() => fetchTransactions()}
          >
            <Filter className="w-5 h-5" />
            <span>Refresh</span>
          </button>
        </div>

        {/* Transactions List */}
        <div className="bg-gray-800 rounded-xl shadow-xl overflow-hidden">
          {transactions.length > 0 ? (
            <div className="divide-y divide-gray-700">
              {transactions.map((tx) => (
                <div key={tx.id} className="p-4 hover:bg-gray-700 transition-colors">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      <div className={`p-2 rounded-full ${tx.type === 'send' ? 'bg-red-500/20' : 'bg-green-500/20'}`}>
                        {tx.type === 'send' ? (
                          <ArrowUpRight className={`w-6 h-6 ${tx.type === 'send' ? 'text-red-500' : 'text-green-500'}`} />
                        ) : (
                          <ArrowDownLeft className={`w-6 h-6 ${tx.type === 'send' ? 'text-red-500' : 'text-green-500'}`} />
                        )}
                      </div>
                      <div>
                        <p className="font-medium">{tx.type === 'send' ? 'Sent' : 'Received'}</p>
                        <p className="text-sm text-gray-400 truncate max-w-xs">{tx.address}</p>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="font-medium">{tx.amount}</p>
                      <p className="text-sm text-gray-400">${tx.usdValue}</p>
                    </div>
                  </div>
                  <div className="mt-2 flex items-center gap-2 text-sm text-gray-400">
                    <Clock className="w-4 h-4" />
                    <span>{tx.timestamp}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center py-16 px-4">
              <DollarSign className="w-16 h-16 text-gray-600 mb-4" />
              <h3 className="text-xl font-medium text-gray-300 mb-2">No Transactions made yet</h3>
              <p className="text-gray-500 text-center mb-4">Start your first transaction to see it displayed here.</p>
              <button 
                onClick={fetchTransactions}
                className="bg-amber-600 hover:bg-amber-700 px-6 py-2 rounded-lg transition-colors"
              >
                Refresh
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionsPage;