import React, { useState, useEffect } from 'react';
import Header from './header'; 
import { 
  TrendingUp, ArrowDown, AlertTriangle, 
  CircleDollarSign, ArrowUpRight, ArrowDownRight,
  AlertCircle, BarChart3, Percent, DollarSign
} from 'lucide-react';

const MarketDashboard = () => {
  const [marketData, setMarketData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = () => {
      try {
        // Simulated data - replace with actual API call
        const mockData = {
          assets: [
            { name: 'Bitcoin', symbol: 'BTC', price: 66750.20, volume: '24.5B', change: 2.5 },
            { name: 'Ethereum', symbol: 'ETH', price: 3280.15, volume: '12.8B', change: -1.2 },
            { name: 'Solana', symbol: 'SOL', price: 180.45, volume: '5.2B', change: 5.8 },
            { name: 'Cardano', symbol: 'ADA', price: 0.65, volume: '2.1B', change: -0.8 },
            { name: 'BNB', symbol: 'BNB', price: 580.25, volume: '4.8B', change: 1.2 },
            { name: 'XRP', symbol: 'XRP', price: 0.85, volume: '3.2B', change: -2.1 },
            { name: 'Avalanche', symbol: 'AVAX', price: 45.30, volume: '1.8B', change: 3.4 },
            { name: 'Polygon', symbol: 'MATIC', price: 0.95, volume: '1.5B', change: 0.7 }
          ],
          topTraded: [
            { name: 'Bitcoin', symbol: 'BTC', volume: '24.5B', buyers: 65 },
            { name: 'Ethereum', symbol: 'ETH', volume: '12.8B', buyers: 58 },
            { name: 'Solana', symbol: 'SOL', volume: '5.2B', buyers: 72 },
            { name: 'BNB', symbol: 'BNB', volume: '4.8B', buyers: 45 }
          ]
        };
        setMarketData(mockData);
        setLoading(false);
      } catch (err) {
        setError('Error fetching market data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-900">
        <BarChart3 className="text-yellow-500 animate-pulse" size={32} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-900">
        <AlertTriangle className="text-red-500 mr-2" />
        <span className="text-red-500">{error}</span>
      </div>
    );
  }

  const topGainers = [...marketData.assets]
    .sort((a, b) => b.change - a.change)
    .slice(0, 4);

  const topLosers = [...marketData.assets]
    .sort((a, b) => a.change - b.change)
    .slice(0, 4);

  return (
    <div className="min-h-screen bg-gray-900 p-4 md:p-8">
      <Header />
      <div className="pt-16">
        <h1 className="text-2xl md:text-3xl font-bold text-white mb-2 flex items-center">
          <CircleDollarSign className="text-yellow-500 mr-2" size={28} />
          Market Overview
        </h1>
        <p className="text-gray-400 flex items-center">
          <AlertCircle className="mr-2" size={16} />
          Live market data updates every 60 seconds
        </p>
      </div>

      {/* Market Data Table */}
      <div className="overflow-x-auto">
        <table className="w-full text-left">
          <thead>
            <tr className="border-b border-gray-800">
              <th className="py-4 px-4 text-gray-400 font-medium">
                <div className="flex items-center">
                  Asset
                </div>
              </th>
              <th className="py-4 px-4 text-gray-400 font-medium">
                <div className="flex items-center">
                  <DollarSign className="mr-1" size={16} />
                  Price
                </div>
              </th>
              <th className="py-4 px-4 text-gray-400 font-medium">
                <div className="flex items-center">
                  <BarChart3 className="mr-1" size={16} />
                  Volume (24h)
                </div>
              </th>
              <th className="py-4 px-4 text-gray-400 font-medium">
                <div className="flex items-center">
                  <Percent className="mr-1" size={16} />
                  Change (24h)
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {marketData.assets.map((asset) => (
              <tr 
                key={asset.symbol}
                className="border-b border-gray-800 hover:bg-gray-800/50 transition-colors"
              >
                <td className="py-4 px-4">
                  <div className="flex items-center">
                    <div className="text-white font-medium">{asset.name}</div>
                    <div className="text-gray-500 ml-2 text-sm">{asset.symbol}</div>
                  </div>
                </td>
                <td className="py-4 px-4 text-white">
                  ${asset.price.toLocaleString()}
                </td>
                <td className="py-4 px-4 text-white">
                  ${asset.volume}
                </td>
                <td className="py-4 px-4">
                  <div className={`flex items-center ${asset.change >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                    {asset.change >= 0 ? (
                      <ArrowUpRight size={16} className="mr-1" />
                    ) : (
                      <ArrowDownRight size={16} className="mr-1" />
                    )}
                    {Math.abs(asset.change)}%
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Market Movers */}
      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-4">
          <h2 className="text-lg font-medium text-white flex items-center">
            <ArrowUpRight className="text-green-500 mr-2" size={20} />
            Top Gainers (24h)
          </h2>
          {topGainers.map((asset) => (
            <div key={asset.symbol} className="flex items-center justify-between bg-gray-800/50 p-4 rounded">
              <div className="flex items-center">
                <span className="text-white font-medium">{asset.name}</span>
                <span className="text-gray-500 text-sm ml-2">{asset.symbol}</span>
              </div>
              <span className="text-green-500">+{asset.change}%</span>
            </div>
          ))}
        </div>

        <div className="space-y-4">
          <h2 className="text-lg font-medium text-white flex items-center">
            <ArrowDown className="text-red-500 mr-2" size={20} />
            Top Losers (24h)
          </h2>
          {topLosers.map((asset) => (
            <div key={asset.symbol} className="flex items-center justify-between bg-gray-800/50 p-4 rounded">
              <div className="flex items-center">
                <span className="text-white font-medium">{asset.name}</span>
                <span className="text-gray-500 text-sm ml-2">{asset.symbol}</span>
              </div>
              <span className="text-red-500">{asset.change}%</span>
            </div>
          ))}
        </div>
      </div>

      {/* Trading Activity */}
      <div className="mt-8 space-y-4">
        <h2 className="text-lg font-medium text-white flex items-center">
          <BarChart3 className="text-yellow-500 mr-2" size={20} />
          Top Traded Assets
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {marketData.topTraded.map((asset) => (
            <div key={asset.symbol} className="bg-gray-800/50 p-4 rounded flex items-center justify-between">
              <div className="flex items-center">
                <span className="text-white font-medium">{asset.name}</span>
                <span className="text-gray-500 text-sm ml-2">{asset.symbol}</span>
              </div>
              <div className="flex items-center">
                <span className="text-white mr-4">Vol: ${asset.volume}</span>
                <div className={`px-2 py-1 rounded ${asset.buyers > 50 ? 'bg-green-500/20 text-green-500' : 'bg-red-500/20 text-red-500'}`}>
                  {asset.buyers}% Buyers
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Action Button */}
      <div className="mt-8 flex justify-end">
        <button className="bg-yellow-600 hover:bg-yellow-700 text-white py-2 px-6 rounded flex items-center transition-colors">
          <TrendingUp className="mr-2" size={16} />
          View Full Market
        </button>
      </div>
    </div>
  );
};

export default MarketDashboard;