import React, { useState } from 'react';
import Header from './header'; 

import { MessageCircle, ThumbsUp, Share2, Flag, Send, Search, Reply, MoreVertical, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
const CommentInput = ({ onSubmit, placeholder, buttonText }) => {
  const [commentText, setCommentText] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (commentText.trim()) {
      onSubmit(commentText);
      setCommentText('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex items-center gap-3">
      <input
        type="text"
        value={commentText}
        onChange={(e) => setCommentText(e.target.value)}
        placeholder={placeholder}
        className="flex-1 bg-gray-800 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-yellow-600"
      />
      <button
        type="submit"
        disabled={!commentText.trim()}
        className="bg-yellow-600 hover:bg-yellow-700 text-black font-semibold px-4 py-2 rounded-lg flex items-center gap-2 disabled:opacity-50"
      >
        <Send size={18} />
        {buttonText}
      </button>
    </form>
  );
};

const Comment = ({ comment, onAddReply }) => {
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [replyText, setReplyText] = useState('');

  const handleReplySubmit = () => {
    if (replyText.trim()) {
      onAddReply(comment.id, replyText);
      setReplyText('');
      setShowReplyInput(false);
    }
  };

  return (
    <div className="bg-gray-800 rounded-lg p-4">
      <div className="flex items-center mb-3">
        <img
          src={comment.avatar}
          alt={comment.user}
          className="w-8 h-8 rounded-full"
        />
        <div className="ml-3">
          <h4 className="font-semibold">{comment.user}</h4>
          <p className="text-sm text-gray-400">{comment.timestamp}</p>
        </div>
        <button className="ml-auto p-1 hover:bg-gray-700 rounded-lg">
          <MoreVertical size={16} />
        </button>
      </div>
      <p className="mb-4">{comment.content}</p>
      <div className="flex items-center gap-4 text-gray-400">
        <button className="flex items-center gap-1 hover:text-yellow-600">
          <ThumbsUp size={16} />
          <span>{comment.likes}</span>
        </button>
        <button
          className="flex items-center gap-1 hover:text-yellow-600"
          onClick={() => setShowReplyInput(!showReplyInput)}
        >
          <Reply size={16} />
          <span>Reply</span>
        </button>
        {showReplyInput && (
          <div className="flex-1">
            <CommentInput
              onSubmit={handleReplySubmit}
              placeholder="Write a reply..."
              buttonText="Reply"
            />
          </div>
        )}
      </div>
      {comment.replies?.length > 0 && (
        <div className="mt-4 pl-8 border-l border-gray-700 space-y-4">
          {comment.replies.map((reply) => (
            <Comment
              key={reply.id}
              comment={reply}
              onAddReply={onAddReply}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const ForumPost = ({ post, onAddComment }) => {
  const [showComments, setShowComments] = useState(false);

  const handleAddReply = (commentId, replyText) => {
    // In a real app, this would call an API to add the reply
    console.log('Adding reply to comment:', commentId, replyText);
  };

  return (
    <div className="bg-gray-800 rounded-lg p-4 mb-6">
      {/* Post Header */}
      <div className="flex items-center mb-3">
        <img
          src={post.avatar}
          alt={post.user}
          className="w-10 h-10 rounded-full"
        />
        <div className="ml-3">
          <h3 className="font-semibold">{post.user}</h3>
          <p className="text-sm text-gray-400">{post.timestamp}</p>
        </div>
        <button className="ml-auto p-1 hover:bg-gray-700 rounded-lg">
          <MoreVertical size={20} />
        </button>
      </div>

      {/* Post Content */}
      <p className="mb-4">{post.content}</p>

      {/* Post Actions */}
      <div className="flex items-center gap-6 text-gray-400 mb-4">
        <button className="flex items-center gap-1 hover:text-yellow-600">
          <ThumbsUp size={18} />
          <span>{post.likes}</span>
        </button>
        <button 
          className="flex items-center gap-1 hover:text-yellow-600"
          onClick={() => setShowComments(!showComments)}
        >
          <MessageCircle size={18} />
          <span>{post.comments?.length || 0}</span>
        </button>
        <button className="flex items-center gap-1 hover:text-yellow-600">
          <Share2 size={18} />
        </button>
        <button className="flex items-center gap-1 hover:text-red-500">
          <Flag size={18} />
        </button>
      </div>

      {/* Comments Section */}
      {showComments && (
        <div className="border-t border-gray-700 pt-4">
          <CommentInput
            onSubmit={(text) => onAddComment(post.id, text)}
            placeholder="Write a comment..."
            buttonText="Comment"
          />
          
          <div className="mt-4 space-y-4">
            {post.comments?.map((comment) => (
              <Comment
                key={comment.id}
                comment={comment}
                onAddReply={handleAddReply}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const CryptoForum = () => {
  const navigate = useNavigate();
  const [newPost, setNewPost] = useState('');
  const [posts, setPosts] = useState([
    {
      id: 1,
      user: "CryptoExpert",
      avatar: "/api/placeholder/40/40",
      content: "What are your thoughts on the latest Bitcoin halving event?",
      likes: 24,
      timestamp: "2 hours ago",
      comments: [
        {
          id: 101,
          user: "BTCMiner",
          avatar: "/api/placeholder/40/40",
          content: "The impact on mining profitability will be significant.",
          likes: 8,
          timestamp: "1 hour ago",
          replies: [
            {
              id: 1011,
              user: "CryptoAnalyst",
              avatar: "/api/placeholder/40/40",
              content: "Agreed. Smaller mining operations might need to consolidate.",
              likes: 4,
              timestamp: "45 min ago",
              replies: []
            }
          ]
        },
        {
          id: 102,
          user: "BlockchainDev",
          avatar: "/api/placeholder/40/40",
          content: "Historical data suggests a bull run typically follows the halving.",
          likes: 12,
          timestamp: "30 min ago",
          replies: []
        }
      ]
    },
    {
      id: 2,
      user: "BlockchainDev",
      avatar: "/api/placeholder/40/40",
      content: "Just launched my first DeFi project! Looking for feedback from the community.",
      likes: 15,
      timestamp: "4 hours ago",
      comments: [
        {
          id: 201,
          user: "DeFiExplorer",
          avatar: "/api/placeholder/40/40",
          content: "Interesting concept! Have you considered implementing flash loan protection?",
          likes: 6,
          timestamp: "3 hours ago",
          replies: []
        }
      ]
    },
    {
      id: 3,
      user: "NewUser",
      avatar: "/api/placeholder/40/40",
      content: "Has anyone used the new DeFi lending platform?",
      likes: 8,
      timestamp: "1 hour ago",
      comments: []
    }
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPost.trim()) {
      // Add new post logic here
      setNewPost('');
    }
  };

  const handleAddComment = (postId, commentText) => {
    // In a real app, this would call an API to add the comment
    setPosts(posts.map(post => {
      if (post.id === postId) {
        return {
          ...post,
          comments: [
            {
              id: Date.now(),
              user: "CurrentUser",
              avatar: "/api/placeholder/40/40",
              content: commentText,
              likes: 0,
              timestamp: "Just now",
              replies: []
            },
            ...(post.comments || [])
          ]
        };
      }
      return post;
    }));
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="pt-16 min-h-screen bg-gray-900 text-gray-100">
      <Header/>
      {/* Header */}
      <div className="border-b border-gray-800">
        <div className="container mx-auto px-4 py-6 flex items-center">
          <button
            className="p-2 hover:bg-gray-800 rounded-lg mr-4"
            onClick={handleGoBack}
          >
            <ArrowLeft size={20} />
          </button>
          <h1 className="text-3xl md:text-4xl font-bold mb-2">Crypto Community Forum</h1>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-6">
        {/* Search Bar */}
        <div className="relative mb-6">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          <input
            type="text"
            placeholder="Search discussions..."
            className="w-full bg-gray-800 rounded-lg pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-600"
          />
        </div>

        {/* New Post Form */}
        <div className="bg-gray-800 rounded-lg p-4 mb-6">
          <form onSubmit={handleSubmit}>
            <textarea
              value={newPost}
              onChange={(e) => setNewPost(e.target.value)}
              placeholder="Start a new discussion..."
              className="w-full bg-gray-700 rounded-lg p-3 mb-3 focus:outline-none focus:ring-2 focus:ring-yellow-600"
              rows="3"
            />
            <button
              type="submit"
              disabled={!newPost.trim()}
              className="bg-yellow-600 hover:bg-yellow-700 text-black font-semibold px-6 py-2 rounded-lg flex items-center gap-2 disabled:opacity-50"
            >
              <Send size={18} />
              Post Discussion
            </button>
          </form>
        </div>

        {/* Forum Posts */}
        <div className="space-y-4">
          {posts.map((post) => (
            <ForumPost
              key={post.id}
              post={post}
              onAddComment={handleAddComment}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CryptoForum;