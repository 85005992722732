"use client"

import React, { useState, useEffect } from 'react';
import Header from './header';
import { Link } from 'react-router-dom';
import {
  Wallet,
  ArrowDownToLine,
  ArrowUpToLine,
  Bitcoin,
  PieChart,
  TrendingUp,
  Bell,
  Menu,
  X,
  ChevronUp,
  GemIcon,
  Sparkles,
  Activity,
  Globe,
  TrendingDown,
  Loader
} from 'lucide-react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

export default function CryptoDashboard() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState('1D');
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [btcPrice, setBtcPrice] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [marketOverview, setMarketOverview] = useState([]);
  const [isMarketLoading, setIsMarketLoading] = useState(true);
  const [marketError, setMarketError] = useState(null);

  useEffect(() => {
    const fetchBtcPrice = async () => {
      try {
        const response = await fetch('https://api.coindesk.com/v1/bpi/currentprice.json');
        if (!response.ok) throw new Error('Failed to fetch BTC price');
        const data = await response.json();
        setBtcPrice(data.bpi.USD.rate_float);

        // Generate chart data based on current price
        const newChartData = generateChartData(data.bpi.USD.rate_float);
        setChartData(newChartData);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBtcPrice();
    // Fetch price every 60 seconds
    const interval = setInterval(fetchBtcPrice, 60000);

    // Fetch market overview
    const fetchMarketOverview = async () => {
      setIsMarketLoading(true);
      try {
        const response = await fetch('https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest?limit=5', {
          headers: {
            'X-CMC_PRO_API_KEY': 'df23b163-36da-42e2-9f93-9799c15619f5'
          }
        });
        if (!response.ok) throw new Error('Failed to fetch market data');
        const data = await response.json();
        setMarketOverview(data.data);
      } catch (err) {
        setMarketError(err.message);
      } finally {
        setIsMarketLoading(false);
      }
    };

    fetchMarketOverview();
    // Fetch market data every 5 minutes
    const marketInterval = setInterval(fetchMarketOverview, 300000);

    return () => {
      clearInterval(interval);
      clearInterval(marketInterval);
    };
  }, []);

  const generateChartData = (currentPrice) => {
    const data = [];
    const now = new Date();
    for (let i = 24; i >= 0; i--) {
      const time = new Date(now.getTime() - i * 60 * 60 * 1000);
      const value = currentPrice * (1 + (Math.random() - 0.5) * 0.1); // Add some random variation
      data.push({
        time: time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        value: value.toFixed(2)
      });
    }
    return data;
  };

  const formatUSD = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    }).format(amount);
  };

  const renderTransactionSection = () => {
    if (isLoading) {
      return (
        <div className="flex items-center justify-center h-40">
          <Loader className="h-6 w-6 animate-spin text-yellow-500" />
        </div>
      );
    }

    if (error) {
      return (
        <div className="text-center text-gray-400 h-40 flex items-center justify-center">
          <p>Unable to load transactions</p>
        </div>
      );
    }

    if (!transactions.length) {
      return (
        <div className="text-center text-gray-400 h-40 flex items-center justify-center">
          <p>No recent transactions</p>
        </div>
      );
    }

    return (
      <div className="space-y-3">
        {transactions.map((transaction, index) => (
          <div key={index} className="flex items-center justify-between p-3 bg-gray-700/50 rounded-lg">
            <div className="flex items-center gap-2">
              <div className="bg-blue-500/20 p-1.5 rounded-lg">
                <Activity className="h-4 w-4 text-blue-500" />
              </div>
              <div>
                <span className="text-sm font-medium">{transaction.type}</span>
                <p className="text-xs text-gray-400">{transaction.amount}</p>
              </div>
            </div>
            <div className="text-right">
              <span className="text-xs text-gray-400">{transaction.time}</span>
              <p className={`text-xs ${transaction.status === 'Completed' ? 'text-green-500' : 'text-yellow-500'
                }`}>{transaction.status}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderMarketOverview = () => {
    if (isMarketLoading) {
      return (
        <div className="flex items-center justify-center h-40">
          <Loader className="h-6 w-6 animate-spin text-yellow-500" />
        </div>
      );
    }

    if (marketError) {
      return (
        <div className="text-center text-gray-400 h-40 flex items-center justify-center">
          <p>Unable to load market data</p>
        </div>
      );
    }

    return (
      <div className="space-y-3">
        {marketOverview.map((coin) => (
          <div key={coin.id} className="flex items-center justify-between p-3 bg-gray-700/50 rounded-lg">
            <div className="flex items-center gap-2">
              <div className="bg-yellow-500/20 p-1.5 rounded-lg">
                <Bitcoin className="h-4 w-4 text-yellow-500" />
              </div>
              <div>
                <span className="text-sm font-medium">{coin.symbol}</span>
                <p className="text-xs text-gray-400">{formatUSD(coin.quote.USD.price)}</p>
              </div>
            </div>
            <span className={`text-xs flex items-center ${coin.quote.USD.percent_change_24h >= 0 ? 'text-green-500' : 'text-red-500'}`}>
              {coin.quote.USD.percent_change_24h >= 0 ? <ChevronUp className="h-3 w-3" /> : <TrendingDown className="h-3 w-3" />}
              {Math.abs(coin.quote.USD.percent_change_24h).toFixed(2)}%
            </span>
          </div>
        ))}
      </div>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-gray-800 p-2 rounded-lg shadow-lg border border-gray-700">
          <p className="text-gray-300">{`Time: ${label}`}</p>
          <p className="text-yellow-500 font-bold">{`Price: ${formatUSD(payload[0].value)}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="pt-16 min-h-screen bg-black text-gray-100">
      <Header />
      {/* Announcement Banner */}
      <div className="bg-yellow-500/10 backdrop-blur-sm py-2 px-3 border-b border-yellow-500/20">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className="animate-pulse bg-yellow-500 rounded-full p-1.5">
              <Sparkles className="h-4 w-4 text-gray-900" />
            </div>
            <div>
              <p className="text-lg font-medium text-yellow-500">New User Special Offer! 🎉</p>
              <p className="text-sm text-yellow-100/80">Get $25 worth of BTC assets in rewards! Fund your account with $100 or above</p>
            </div>
          </div>
          <button className="text-yellow-500 hover:text-yellow-400 transition-colors">
            <X className="h-4 w-4" />
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto p-3 space-y-4">
        {/* Stats Overview */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {/* Balance Card */}
          <div className="lg:col-span-2 bg-gray-800/50 backdrop-blur-xl rounded-xl p-4 border border-gray-700/50">
            <div className="flex flex-col h-full">
              <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-4">
                <div>
                  <h2 className="text-xs text-gray-400">My Current Balance</h2>
                  <div className="flex items-center gap-2 mt-1">
                    <div className="bg-yellow-500/20 rounded-lg p-1.5">
                      <Bitcoin className="h-5 w-5 text-yellow-500" />
                    </div>
                    <div className="flex flex-col">
                      <span className="text-xl font-bold">$0.00</span>
                      <span className="text-xs text-gray-400">0.000 BTC</span>
                    </div>
                  </div>
                </div>
                <div className="flex gap-3">
                  <Link to="/deposit">
                    <button className="px-4 py-2 bg-yellow-600 hover:bg-yellow-700 rounded-lg text-sm font-medium transition-colors">
                      Deposit
                    </button>
                  </Link>
                  <Link to="/withdraw">
                    <button className="px-4 py-2 border border-yellow-600 text-yellow-600 hover:bg-yellow-600 hover:text-white rounded-lg text-sm font-medium transition-colors">
                      Transfer
                    </button>
                  </Link>
                </div>
              </div>

              {/* Enhanced Chart */}
              <div className="h-[300px] w-full">
                <div className="flex items-center gap-2 mb-4 overflow-x-auto">
                  {['1H', '1D', '1W', '1M', '1Y'].map((timeframe) => (
                    <button
                      key={timeframe}
                      onClick={() => setSelectedTimeframe(timeframe)}
                      className={`px-2 py-1 rounded-lg text-xs transition-all ${selectedTimeframe === timeframe
                          ? 'bg-yellow-500 text-gray-900'
                          : 'text-gray-400 hover:text-gray-200'
                        }`}
                    >
                      {timeframe}
                    </button>
                  ))}
                </div>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>
                      <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#EAB308" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#EAB308" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <XAxis
                      dataKey="time"
                      stroke="#6B7280"
                      tick={{ fill: '#9CA3AF', fontSize: 12 }}
                      axisLine={false}
                      tickLine={false}
                    />
                    <YAxis
                      stroke="#6B7280"
                      tick={{ fill: '#9CA3AF', fontSize: 12 }}
                      axisLine={false}
                      tickLine={false}
                      tickFormatter={(value) => `$${value.toLocaleString()}`}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Area
                      type="monotone"
                      dataKey="value"
                      stroke="#EAB308"
                      fillOpacity={1}
                      fill="url(#colorValue)"
                      strokeWidth={2}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          {/* Quick Stats */}
          <div className="space-y-4">
            {/* Account Balances */}
            <div className="bg-gray-800/50 backdrop-blur-xl rounded-xl p-4 border border-gray-700/50">
              <h3 className="text-sm font-medium mb-3">Account Balances</h3>
              <div className="space-y-3">
                {[
                  { name: 'Funding', icon: Wallet, color: 'blue', amount: 0.00 },
                  { name: 'Spot', icon: PieChart, color: 'green', amount: 0.00 },
                  { name: 'Margin', icon: TrendingUp, color: 'purple', amount: 0.00 }
                ].map((account) => (
                  <div key={account.name} className="flex items-center justify-between p-2 bg-gray-700/50 rounded-lg">
                    <div className="flex items-center gap-2">
                      <div className={`bg-${account.color}-500/20 p-1.5 rounded-lg`}>
                        <account.icon className={`h-4 w-4 text-${account.color}-500`} />
                      </div>
                      <span className="text-sm">{account.name}</span>
                    </div>
                    <span className="text-sm font-medium">{formatUSD(account.amount)}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Quick Actions */}
            <div className="bg-gray-800/50 backdrop-blur-xl rounded-xl p-4 border border-gray-700/50">
              <h3 className="text-sm font-medium mb-3">Quick Actions</h3>
              <div className="grid grid-cols-2 gap-2">
                {[
                  { name: 'Wallet', icon: Bitcoin, color: 'yellow', href: '/wallet' },
                  { name: 'Withdraw', icon: TrendingDown, color: 'red', href: '/withdraw' },
                  { name: 'Convert', icon: Globe, color: 'blue', href: '/convert' },
                  { name: 'Earn', icon: GemIcon, color: 'green', href: '/earncrypto' }
                ].map((action) => (
                  <a
                    key={action.name}
                    href={action.href}
                    className="flex flex-col items-center gap-1 bg-gray-700/50 hover:bg-gray-700 p-3 rounded-lg transition-all"
                  >
                    <div className={`bg-${action.color}-500/20 p-1.5 rounded-lg`}>
                      <action.icon className={`h-4 w-4 text-${action.color}-500`} />
                    </div>
                    <span className="text-xs">{action.name}</span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {/* Market Overview */}
          <div className="bg-gray-800/50 backdrop-blur-xl rounded-xl p-4 border border-gray-700/50">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-sm font-medium">Market Overview</h3>
              <button className="text-xs text-yellow-500 hover:text-yellow-400 transition-colors">
                View All
              </button>
            </div>
            {renderMarketOverview()}
          </div>

          {/* Recent Activity */}
          <div className="bg-gray-800/50 backdrop-blur-xl rounded-xl p-4 border border-gray-700/50">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-sm font-medium">Recent Activity</h3>
              <button className="text-xs text-yellow-500 hover:text-yellow-400 transition-colors">
                View All
              </button>
            </div>
            {renderTransactionSection()}
          </div>
        </div>
      </div>
    </div>
  );
}