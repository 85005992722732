import React, { useState } from 'react';
import Header from './header'; 

import { Newspaper, TrendingUp, Bell, ChevronDown, Search, Star, Activity, Zap } from 'lucide-react';

const CryptoNews = () => {
  const [isHovered, setIsHovered] = useState(null);
  const [expandedNews, setExpandedNews] = useState({});

  const news = [
    {
      id: 1,
      title: "Bitcoin Surges Past Previous Resistance Levels",
      content: "Bitcoin has shown remarkable strength in recent trading sessions, breaking through key resistance levels as institutional interest continues to grow.",
      expandedContent: `Bitcoin has demonstrated exceptional momentum in the latest trading sessions, shattering multiple resistance levels that have historically constrained its price movement. This breakthrough comes amid increasing institutional adoption and growing market confidence.

Key points driving this surge include:
• Major institutional investments from leading financial firms
• Improved market sentiment following regulatory clarity
• Technical indicators suggesting strong bullish momentum
• Reduced selling pressure from long-term holders

Market analysts suggest this could be the beginning of a sustained upward trend, with several technical indicators pointing to continued strength. Trading volume has also seen a significant increase, lending credibility to the current price movement.`,
      timestamp: "2 hours ago",
      category: "Market Analysis",
      importance: "High",
      icon: <Activity className="h-5 w-5" />
    },
    {
      id: 2,
      title: "Revolutionary DeFi Protocol Launches",
      content: "A groundbreaking DeFi protocol has launched, promising to revolutionize yield farming with innovative tokenomics and enhanced security features.",
      expandedContent: `The newly launched DeFi protocol represents a significant leap forward in decentralized finance technology. The platform introduces several revolutionary features that address common pain points in existing DeFi solutions.

Notable innovations include:
• Advanced automated market-making algorithms
• Novel approach to impermanent loss protection
• Enhanced security measures with multi-layered auditing
• Sustainable yield generation mechanisms
• Innovative governance model

The protocol's architecture has been audited by leading security firms, and early testing shows promising results for both capital efficiency and user experience. Community response has been overwhelmingly positive, with significant liquidity already locked in the protocol.`,
      timestamp: "4 hours ago",
      category: "DeFi",
      importance: "Medium",
      icon: <Zap className="h-5 w-5" />
    },
    {
      id: 3,
      title: "Major Institutional Adoption News",
      content: "Leading investment firms announce significant cryptocurrency positions, signaling growing institutional confidence in digital assets.",
      expandedContent: `In a landmark development for cryptocurrency adoption, several major investment firms have announced substantial positions in digital assets. This coordinated move signals a significant shift in institutional attitudes toward cryptocurrency as an asset class.

The announcements include:
• Multiple billion-dollar allocations to Bitcoin and Ethereum
• New cryptocurrency-focused investment products
• Strategic partnerships with major crypto infrastructure providers
• Plans for expanded crypto custody services

Industry experts suggest this could trigger a domino effect of institutional adoption, potentially leading to increased market stability and mainstream acceptance of digital assets as a legitimate investment vehicle.`,
      timestamp: "6 hours ago",
      category: "Adoption",
      importance: "High",
      icon: <Star className="h-5 w-5" />
    }
  ];

  const toggleExpand = (id) => {
    setExpandedNews(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  return (
    <div className="pt-16 min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      <div className="fixed inset-0 bg-grid-pattern opacity-5"></div>
      <Header/>
      {/* Header */}
     

      {/* Main Content */}
      <main className="container mx-auto px-4 py-6">
        <section>
          <h2 className="text-lg font-bold mb-4 text-gray-100">Breaking Updates</h2>
          <div className="space-y-4">
            {news.map((item) => (
              <div 
                key={item.id}
                className="bg-gray-800/50 backdrop-blur-sm border-2 border-gray-700/50 hover:bg-gray-750/50 transition-all duration-300 hover:border-amber-500/50 group rounded-lg"
              >
                <div className="p-4">
                  <div className="flex justify-between items-start mb-4">
                    <div className="flex items-center space-x-3">
                      <div className="text-amber-500 group-hover:scale-110 transition-transform">
                        {item.icon}
                      </div>
                      <h3 className="text-base font-medium text-gray-100">{item.title}</h3>
                    </div>
                    <span className="text-xs px-2 py-1 rounded-full bg-amber-500/10 text-amber-500 border border-amber-500/20">
                      {item.category}
                    </span>
                  </div>
                  <div className={`transition-all duration-300 overflow-hidden ${expandedNews[item.id] ? 'max-h-[1000px]' : 'max-h-20'}`}>
                    <p className="text-gray-300 text-sm mb-4">
                      {expandedNews[item.id] ? item.expandedContent : item.content}
                    </p>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-xs text-gray-400">{item.timestamp}</span>
                    <button 
                      onClick={() => toggleExpand(item.id)}
                      className="bg-gradient-to-r from-amber-500/10 to-yellow-500/10 text-amber-500 px-3 py-1.5 rounded-lg flex items-center space-x-2 group-hover:from-amber-500/20 group-hover:to-yellow-500/20 transition-all text-sm"
                    >
                      <span>{expandedNews[item.id] ? 'Show less' : 'Read more'}</span>
                      <ChevronDown 
                        className={`h-3 w-3 transition-transform ${expandedNews[item.id] ? 'rotate-180' : ''}`} 
                      />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};

export default CryptoNews;