import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './login';
import Header from './header';
import MyWallet from './wallet';
import CryptoDashboard from './homepage';
import CryptoLanding from './landingpage';
import OrdersAndLoans from './ordersloans';
import DepositPage from './deposit';
import EarnCryptoDashboard from './earncrypto';
import CopyTradingWebsite from './copytrading';
import WithdrawPage from './withdraw';
import HelpCenter from './helpcenter'
import MarketDashboard from './marketoverview'
import NotificationSystem from './notifications'
import SignupPage from './signup.js'
import CryptoSettings from './settings'
import CryptoBlog from './blog'
import CryptoConverter from './convert'
import CryptoNews from './marketnews'
import CryptoForum from './community'
import Comment from './Comment'
import CryptoEducation from './education'
import CryptoOrders from './orders'
import TransactionsPage from './transactions'
import CryptoEvents from './events'

import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Coins } from 'lucide-react';
const LoadingScreen = () => {
  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center">
      <div className="relative">
        {/* Animated Logo */}
        <div className="relative z-10">
          <Coins className="h-20 w-20 text-yellow-500 animate-bounce" />
        </div>
        
        {/* Glowing Effect */}
        <div className="absolute inset-0 bg-yellow-500 opacity-20 rounded-full blur-2xl animate-pulse"></div>
      </div>

      {/* Loading Text */}
      <h2 className="mt-8 text-2xl font-bold text-white">
        Loading your experience
      </h2>
      
      {/* Loading Bar */}
      <div className="mt-6 w-48 h-1.5 bg-gray-700 rounded-full overflow-hidden">
        <div className="h-full bg-yellow-500 rounded-full animate-loadingBar"></div>
      </div>

      {/* Loading Message */}
      <p className="mt-4 text-gray-400 text-sm animate-pulse">
        Please wait while we secure your connection
      </p>
    </div>
  );
};
const ProtectedRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <LoadingScreen />; 
  }

  if (!user) {
    return <Navigate to="/" />;
  }

  return children;
};
const App = () => {
  console.log("App component rendering"); // Debug log

  return (
    <div style={{ height: '100vh', backgroundColor: 'white' }}>
      <Routes>
        <Route path="/" element={<CryptoLanding/>} />
        <Route
          path="/homepage"
          element={
            <ProtectedRoute>
              <CryptoDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ordersloans"
          element={
            <ProtectedRoute>
              <OrdersAndLoans />
            </ProtectedRoute>
          }
        />
        <Route
          path="/deposit"
          element={
            <ProtectedRoute>
              <DepositPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/earncrypto"
          element={
            <ProtectedRoute>
              <EarnCryptoDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/copytrading"
          element={
            <ProtectedRoute>
              <CopyTradingWebsite />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wallet"
          element={
            <ProtectedRoute>
              <MyWallet />
            </ProtectedRoute>
          }
        />
        <Route
          path="/withdraw"
          element={
            <ProtectedRoute>
              <WithdrawPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/helpcenter"
          element={
            <ProtectedRoute>
              <HelpCenter />
            </ProtectedRoute>
          }
        />
        <Route
          path="/marketoverview"
          element={
            <ProtectedRoute>
              <MarketDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <ProtectedRoute>
              <NotificationSystem />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <CryptoSettings />
            </ProtectedRoute>
          }
        />

        
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/ordersloans" element={<OrdersAndLoans />} />
        <Route path="/deposit" element={<DepositPage />} />
        <Route path="/earncrypto" element={<EarnCryptoDashboard />} />
        <Route path="/copytrading" element={<CopyTradingWebsite />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/header" element={<Header />} />
        <Route path="/wallet" element={<MyWallet />} />
        <Route path="/withdraw" element={<WithdrawPage />} />
        <Route path="/helpcenter" element={<HelpCenter />} />
        <Route path="/marketoverview" element={<MarketDashboard />} />
        <Route path="/notifications" element={<NotificationSystem />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/settings" element={<CryptoSettings />} />
        <Route path="/blog" element={<CryptoBlog />} />
        <Route path="/convert" element={<CryptoConverter />} />
        <Route path="/marketnews" element={<CryptoNews />} />
        <Route path="/community" element={<CryptoForum />} />
        <Route path="/Comment" element={<Comment />} />
        <Route path="/education" element={<CryptoEducation />} />
        <Route path="/orders" element={<CryptoOrders />} />
        <Route path="/transactions" element={<TransactionsPage />} />
        <Route path="/events" element={<CryptoEvents />} />




      </Routes>
    </div>
  );
};

export default App;