import React, { useState } from 'react';
import { MessageCircle, ThumbsUp, Share2, Flag, Send, Search, Reply, MoreVertical, X } from 'lucide-react';

// Reusable Comment Input Component
const CommentInput = ({ onSubmit, placeholder, buttonText, initialValue = '', onCancel = null }) => {
  const [text, setText] = useState(initialValue);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (text.trim()) {
      onSubmit(text);
      setText('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-2">
      <div className="flex flex-col sm:flex-row gap-2">
        <input
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder={placeholder}
          className="flex-1 bg-gray-700 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-600 w-full"
        />
        <div className="flex gap-2 w-full sm:w-auto">
          <button
            type="submit"
            disabled={!text.trim()}
            className="bg-yellow-600 hover:bg-yellow-700 text-black px-4 py-2 rounded-lg font-semibold disabled:opacity-50 flex-1 sm:flex-none"
          >
            {buttonText}
          </button>
          {onCancel && (
            <button
              type="button"
              onClick={onCancel}
              className="px-4 py-2 rounded-lg font-semibold bg-gray-700 hover:bg-gray-600 flex-1 sm:flex-none"
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

// Comment Component
const Comment = ({ comment, depth = 0, onAddReply }) => {
  const [showReplyInput, setShowReplyInput] = useState(false);

  const handleReply = (text) => {
    onAddReply(comment.id, text);
    setShowReplyInput(false);
  };

  return (
    <div className={`${depth > 0 ? 'ml-2 md:ml-6 pl-4 border-l border-gray-700' : ''} mb-4`}>
      <div className="bg-gray-800/50 rounded-lg p-4">
        {/* Comment Header */}
        <div className="flex items-center mb-3">
          <img
            src={comment.avatar}
            alt={comment.user}
            className="w-8 h-8 rounded-full"
          />
          <div className="ml-3">
            <h4 className="font-semibold text-sm">{comment.user}</h4>
            <p className="text-xs text-gray-400">{comment.timestamp}</p>
          </div>
          <button className="ml-auto p-1 hover:bg-gray-700 rounded-lg">
            <MoreVertical size={16} />
          </button>
        </div>

        {/* Comment Content */}
        <p className="text-sm mb-3">{comment.content}</p>

        {/* Comment Actions */}
        <div className="flex items-center gap-4 text-gray-400 text-sm">
          <button className="flex items-center gap-1 hover:text-yellow-600">
            <ThumbsUp size={14} />
            <span>{comment.likes}</span>
          </button>
          <button 
            onClick={() => setShowReplyInput(!showReplyInput)}
            className="flex items-center gap-1 hover:text-yellow-600"
          >
            <Reply size={14} />
            <span>Reply</span>
          </button>
          <button className="flex items-center gap-1 hover:text-red-500">
            <Flag size={14} />
          </button>
        </div>

        {/* Reply Input */}
        {showReplyInput && (
          <CommentInput
            onSubmit={handleReply}
            placeholder="Write a reply..."
            buttonText="Reply"
            onCancel={() => setShowReplyInput(false)}
          />
        )}
      </div>

      {/* Nested Replies */}
      {comment.replies?.length > 0 && (
        <div className="mt-2">
          {comment.replies.map((reply) => (
            <Comment
              key={reply.id}
              comment={reply}
              depth={depth + 1}
              onAddReply={onAddReply}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Comment;