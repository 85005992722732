import React, { useState, useEffect } from 'react';
import { 
  User, 
  ImageIcon, 
  Lock,
  MessageSquare,
  Bitcoin,
  Clock,
  Info,
  Shield,
  ArrowLeft
} from 'lucide-react';
import { database, auth } from './firebase';
import { ref, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom';

const CryptoSettings = () => {
  const [username, setUsername] = useState('');
  const [lastUsernameUpdate, setLastUsernameUpdate] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userRef = ref(database, `users/${user.uid}`);
      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData) {
          setUsername(userData.username || '');
          setLastUsernameUpdate(userData.lastUsernameUpdate || null);
        }
        setLoading(false);
      });
    }
  }, []);

  const daysUntilEdit = () => {
    if (!lastUsernameUpdate) return 60;
    const daysSinceLastUpdate = Math.floor(
      (Date.now() - lastUsernameUpdate) / (1000 * 60 * 60 * 24)
    );
    return Math.max(60 - daysSinceLastUpdate, 0);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      {/* Header */}
      <div className="sticky top-0 bg-gray-900/95 backdrop-blur-sm border-b border-gray-800 px-4 py-4 md:px-6">
        <div className="max-w-6xl mx-auto flex items-center justify-between">
          <div className="flex items-center gap-4">
            <button 
              onClick={handleBack}
              className="p-2 hover:bg-gray-800 rounded-lg transition-colors"
            >
              <ArrowLeft className="w-5 h-5 text-gray-400 hover:text-gray-100" />
            </button>
            <div className="flex items-center gap-3">
              <Bitcoin className="w-8 h-8 text-yellow-500" />
              <h1 className="text-xl md:text-2xl font-bold">Account Security</h1>
            </div>
          </div>
          <div className="flex items-center gap-2 text-yellow-500">
            <Shield className="w-5 h-5" />
            <span className="text-sm font-medium hidden sm:inline">Enhanced Protection Active</span>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-6xl mx-auto p-4 md:p-6 space-y-6">
        {/* Profile Section */}
        <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-gray-700">
          <div className="p-4 md:p-6 border-b border-gray-700">
            <h2 className="text-lg md:text-xl font-semibold flex items-center gap-2">
              <User className="text-yellow-500" />
              Identity Protection
            </h2>
          </div>
          
          <div className="p-4 md:p-6 space-y-6">
            {/* Profile Picture */}
            <div className="relative">
              <div className="flex flex-col md:flex-row md:items-center gap-6">
                <div className="relative">
                  <div className="w-24 h-24 bg-gray-700 rounded-full flex items-center justify-center group">
                    <ImageIcon className="w-8 h-8 text-gray-400" />
                    <div className="absolute inset-0 bg-gray-900/80 rounded-full flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                      <Lock className="w-6 h-6 text-yellow-500" />
                    </div>
                  </div>
                </div>
                
                <div className="flex-1">
                  <div className="p-4 rounded-lg bg-gray-700/30 border border-gray-700">
                    <div className="flex items-start gap-3">
                      <Info className="w-5 h-5 text-blue-400 flex-shrink-0 mt-1" />
                      <div className="space-y-1">
                        <h3 className="font-medium text-blue-400">Profile Picture Protection</h3>
                        <p className="text-sm text-gray-400">Profile pictures are currently locked to prevent impersonation attempts in the crypto community. This helps maintain trust and security among traders.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Username */}
            <div className="space-y-4">
              <div className="flex items-center gap-3">
                <label className="block text-sm font-medium">Your Protected Username</label>
                <Lock className="w-4 h-4 text-yellow-500" />
              </div>
              <div className="relative">
                <input
                  type="text"
                  value={username}
                  className="w-full bg-gray-700/50 rounded-lg px-4 py-3 focus:outline-none border border-gray-600 text-gray-400"
                  disabled
                />
                <div className="absolute right-3 top-1/2 -translate-y-1/2 flex items-center gap-2 text-yellow-500">
                  <Clock className="w-4 h-4" />
                  <span className="text-sm font-medium">{daysUntilEdit()} days locked</span>
                </div>
              </div>
              
              <div className="p-4 rounded-lg bg-yellow-500/10 border border-yellow-500/20">
                <div className="flex gap-3">
                  <Shield className="w-5 h-5 text-yellow-500 flex-shrink-0 mt-1" />
                  <div className="space-y-2">
                    <p className="text-sm text-yellow-500">
                      Your username is protected for {daysUntilEdit()} more days to ensure account security. This measure helps prevent:
                    </p>
                    <ul className="text-sm text-gray-400 space-y-1 list-disc pl-4">
                      <li>Identity theft in crypto communities</li>
                      <li>Impersonation attempts in trading groups</li>
                      <li>Confusion among your followers</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Posts Section */}
        <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-gray-700">
          <div className="p-4 md:p-6 border-b border-gray-700">
            <h2 className="text-lg md:text-xl font-semibold flex items-center gap-2">
              <MessageSquare className="text-yellow-500" />
              Your Posts
            </h2>
          </div>

          <div className="p-12 flex flex-col items-center justify-center text-center text-gray-400">
            <MessageSquare className="w-16 h-16 mb-4 opacity-20" />
            <h3 className="text-lg font-medium mb-2">Ready for Your First Post</h3>
            <p className="text-sm max-w-md">
              Your profile is secured. Start sharing your crypto insights while maintaining your protected identity.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CryptoSettings;