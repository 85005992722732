import React, { useState } from 'react';
import { 
  HelpCircle, 
  Ticket, 
  Mail, 
  ChevronDown, 
  ChevronUp,
  Search,
  Copy,
  Check,
  AlertCircle,
  Loader
} from 'lucide-react';

const HelpCenter = () => {
  const [activeTab, setActiveTab] = useState('faq');
  const [searchTerm, setSearchTerm] = useState('');
  const [ticketNumber, setTicketNumber] = useState(null);
  const [expandedFaq, setExpandedFaq] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [submitSuccess, setSubmitSuccess] = useState(false);
  
  // Form states
  const [ticketForm, setTicketForm] = useState({
    subject: '',
    description: ''
  });
  
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    message: ''
  });

  const faqData = [
    {
      id: 1,
      question: "How do I reset my 2FA?",
      answer: "To reset your 2FA, please submit a ticket with your account details and our security team will assist you within 24 hours."
    },
    {
      id: 2,
      question: "What are the withdrawal limits?",
      answer: "Withdrawal limits vary based on your verification level. Basic accounts can withdraw up to $5,000 daily, while verified accounts can withdraw up to $100,000 daily."
    },
    {
      id: 3,
      question: "How long do deposits take?",
      answer: "Crypto deposits typically require 2-6 network confirmations. The time varies by blockchain, usually taking 10-60 minutes."
    },
    {
      id: 4,
      question: "How do I verify my account?",
      answer: "To verify your account, go to the 'Verification' section and submit valid government-issued ID along with a selfie. Processing typically takes 1-3 business days."
    },
    {
      id: 5,
      question: "What cryptocurrencies do you support?",
      answer: "We support major cryptocurrencies including Bitcoin, Ethereum, and various popular altcoins. Check our 'Assets' page for a complete list of supported cryptocurrencies."
    },
    {
      id: 6,
      question: "How secure is my crypto?",
      answer: "We employ industry-leading security measures including cold storage, multi-sig wallets, and regular security audits. 95% of user funds are stored in cold wallets."
    }
  ];

  const validateEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  const validateForm = (type) => {
    const errors = {};
    
    if (type === 'ticket') {
      if (!ticketForm.subject.trim()) errors.subject = 'Subject is required';
      if (!ticketForm.description.trim()) errors.description = 'Description is required';
    } else if (type === 'contact') {
      if (!contactForm.name.trim()) errors.name = 'Name is required';
      if (!contactForm.email.trim()) errors.email = 'Email is required';
      else if (!validateEmail(contactForm.email)) errors.email = 'Invalid email format';
      if (!contactForm.message.trim()) errors.message = 'Message is required';
    }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const generateTicket = async () => {
    if (!validateForm('ticket')) return;
    
    setIsLoading(true);
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1500));
      const newTicket = Math.floor(100000 + Math.random() * 900000);
      setTicketNumber(newTicket);
      setSubmitSuccess(true);
      setTicketForm({ subject: '', description: '' });
    } catch (error) {
      console.error('Error generating ticket:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyTicket = async () => {
    try {
      await navigator.clipboard.writeText(ticketNumber.toString());
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  const handleContactSubmit = async () => {
    if (!validateForm('contact')) return;
    
    setIsLoading(true);
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1500));
      setSubmitSuccess(true);
      setContactForm({ name: '', email: '', message: '' });
      setTimeout(() => setSubmitSuccess(false), 3000);
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredFaqs = faqData.filter(faq =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderInput = (label, name, value, onChange, type = 'text', form = 'contact') => (
    <div>
      <label className="block mb-2">{label}</label>
      <input
        type={type}
        value={value}
        onChange={onChange}
        className={`w-full bg-gray-700 rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-yellow-600 
          ${formErrors[name] ? 'border-2 border-red-500' : ''}`}
        placeholder={`Enter your ${label.toLowerCase()}`}
      />
      {formErrors[name] && (
        <p className="text-red-500 text-sm mt-1 flex items-center gap-1">
          <AlertCircle size={16} />
          {formErrors[name]}
        </p>
      )}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 p-4 md:p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl md:text-4xl font-bold mb-8 text-center">How can we help you?</h1>
        
        {/* Navigation Tabs */}
        <div className="flex flex-wrap gap-4 mb-8 justify-center">
          <button
            onClick={() => setActiveTab('faq')}
            className={`flex items-center gap-2 px-6 py-3 rounded-lg transition-colors duration-200 ${
              activeTab === 'faq' 
                ? 'bg-yellow-600 text-white' 
                : 'bg-gray-800 hover:bg-gray-700'
            }`}
          >
            <HelpCircle size={20} />
            FAQ
          </button>
          <button
            onClick={() => setActiveTab('ticket')}
            className={`flex items-center gap-2 px-6 py-3 rounded-lg transition-colors duration-200 ${
              activeTab === 'ticket' 
                ? 'bg-yellow-600 text-white' 
                : 'bg-gray-800 hover:bg-gray-700'
            }`}
          >
            <Ticket size={20} />
            Submit Ticket
          </button>
          <button
            onClick={() => setActiveTab('contact')}
            className={`flex items-center gap-2 px-6 py-3 rounded-lg transition-colors duration-200 ${
              activeTab === 'contact' 
                ? 'bg-yellow-600 text-white' 
                : 'bg-gray-800 hover:bg-gray-700'
            }`}
          >
            <Mail size={20} />
            Contact Us
          </button>
        </div>
  
        {/* Content Sections with Illustrator */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 bg-gray-800 rounded-xl p-6 shadow-xl">
          <div className="md:col-span-1">
            {activeTab === 'faq' && (
              <div>
                <div className="relative mb-6">
                  <input
                    type="text"
                    placeholder="Search FAQ..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full bg-gray-700 rounded-lg px-4 py-3 pl-12 focus:outline-none focus:ring-2 focus:ring-yellow-600"
                  />
                  <Search className="absolute left-4 top-3.5 text-gray-400" size={20} />
                </div>
                
                <div className="space-y-4">
                  {filteredFaqs.length > 0 ? (
                    filteredFaqs.map((faq) => (
                      <div 
                        key={faq.id}
                        className="bg-gray-700 rounded-lg p-4 cursor-pointer hover:bg-gray-600 transition-colors"
                        onClick={() => setExpandedFaq(expandedFaq === faq.id ? null : faq.id)}
                      >
                        <div className="flex justify-between items-center">
                          <h3 className="font-medium">{faq.question}</h3>
                          {expandedFaq === faq.id ? (
                            <ChevronUp size={20} />
                          ) : (
                            <ChevronDown size={20} />
                          )}
                        </div>
                        {expandedFaq === faq.id && (
                          <p className="mt-4 text-gray-300">{faq.answer}</p>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="text-center text-gray-400 py-8">
                      No FAQ matches your search. Try different keywords or check our other support options.
                    </div>
                  )}
                </div>
              </div>
            )}
  
            {activeTab === 'ticket' && (
              <div className="space-y-6">
                <h2 className="text-2xl font-semibold mb-4">Submit a Support Ticket</h2>
                <div className="space-y-4">
                  {renderInput('Subject', 'subject', ticketForm.subject, 
                    (e) => setTicketForm({...ticketForm, subject: e.target.value}), 'text', 'ticket')}
                  
                  <div>
                    <label className="block mb-2">Description</label>
                    <textarea
                      value={ticketForm.description}
                      onChange={(e) => setTicketForm({...ticketForm, description: e.target.value})}
                      className={`w-full bg-gray-700 rounded-lg px-4 py-3 focus:outline-none focus:ring-2 
                        focus:ring-yellow-600 h-32 ${formErrors.description ? 'border-2 border-red-500' : ''}`}
                      placeholder="Describe your issue"
                    />
                    {formErrors.description && (
                      <p className="text-red-500 text-sm mt-1 flex items-center gap-1">
                        <AlertCircle size={16} />
                        {formErrors.description}
                      </p>
                    )}
                  </div>
                  <button
                    onClick={generateTicket}
                    disabled={isLoading}
                    className="bg-yellow-600 hover:bg-yellow-700 text-white px-6 py-3 rounded-lg flex items-center gap-2 
                      disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                  >
                    {isLoading ? (
                      <>
                        <Loader className="animate-spin" size={20} />
                        Generating...
                      </>
                    ) : (
                      <>
                        <Ticket size={20} />
                        Generate Ticket
                      </>
                    )}
                  </button>
                  {ticketNumber && (
                    <div className="bg-gray-700 p-4 rounded-lg mt-4">
                      <div className="flex items-center justify-between">
                        <span>Your ticket number: #{ticketNumber}</span>
                        <button
                          onClick={handleCopyTicket}
                          className="text-yellow-500 hover:text-yellow-400 transition-colors duration-200"
                          disabled={copied}
                        >
                          {copied ? (
                            <Check size={20} className="text-green-500" />
                          ) : (
                            <Copy size={20} />
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
  
            {activeTab === 'contact' && (
              <div className="space-y-6">
                <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
                <div className="space-y-4">
                  {renderInput('Name', 'name', contactForm.name, 
                    (e) => setContactForm({...contactForm, name: e.target.value}))}
                  
                  {renderInput('Email', 'email', contactForm.email, 
                    (e) => setContactForm({...contactForm, email: e.target.value}), 'email')}
                  
                  <div>
                    <label className="block mb-2">Message</label>
                    <textarea
                      value={contactForm.message}
                      onChange={(e) => setContactForm({...contactForm, message: e.target.value})}
                      className={`w-full bg-gray-700 rounded-lg px-4 py-3 focus:outline-none focus:ring-2 
                        focus:ring-yellow-600 h-32 ${formErrors.message ? 'border-2 border-red-500' : ''}`}
                      placeholder="Type your message"
                    />
                    {formErrors.message && (
                      <p className="text-red-500 text-sm mt-1 flex items-center gap-1">
                        <AlertCircle size={16} />
                        {formErrors.message}
                      </p>
                    )}
                  </div>
                  <button
                    onClick={handleContactSubmit}
                    disabled={isLoading}
                    className="bg-yellow-600 hover:bg-yellow-700 text-white px-6 py-3 rounded-lg flex items-center gap-2 
                      disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                  >
                    {isLoading ? (
                      <>
                        <Loader className="animate-spin" size={20} />
                        Sending...
                      </>
                    ) : (
                      <>
                        <Mail size={20} />
                        Send Message
                      </>
                    )}
                  </button>
                  
                  {submitSuccess && (
                    <div className="bg-green-600 text-white p-4 rounded-lg mt-4 flex items-center gap-2">
                      <Check size={20} />
                      Message sent successfully! We'll get back to you soon.
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
  
          {/* Illustrator Column */}
          <div className="hidden md:block">
            {/* Replace this div with your illustrator component or image */}
            <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 64 64" 
      className="h-64 w-64 text-yellow-600">
      <g fill="currentColor">
        <path d="M32 2c-9.94 0-18 8.06-18 18v3c-4.66 0-8 3.34-8 8v31h8V45h32v9h8V31c0-4.66-3.34-8-8-8v-3c0-9.94-8.06-18-18-18zM26 48h12v-2H26v2zm-10-6h32v-5H16v5zm6-10c1.93 0 3.5-1.57 3.5-3.5S23.93 25 22 25s-3.5 1.57-3.5 3.5S20.07 32 22 32zm12 0c1.93 0 3.5-1.57 3.5-3.5S34.93 25 33 25s-3.5 1.57-3.5 3.5S31.07 32 33 32zM21 17h22c1.1 0 2 .9 2 2v3H19v-3c0-1.1.9-2 2-2z"/>
        <path d="M20 35h24c1.1 0 2-.9 2-2s-.9-2-2-2H20c-1.1 0-2 .9-2 2s.9 2 2 2z"/>
      </g>
    </svg>     </div>
        </div>
      </div>
    </div>
  );
  
};

export default HelpCenter;