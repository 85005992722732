import React from 'react';
import Header from './header';
import {
  Trophy,
  Users,
  Copy,
  TrendingUp,
  Shield,
  Globe2,
  ChevronRight,
  Star,
  Wallet
} from 'lucide-react';

const CopyTradingWebsite = () => {
  const traders = [
    {
      name: "Alex Thomson",
      winRate: "89%",
      followers: "2.4K",
      profit: "534%",
      avatar: "/api/placeholder/100/100"
    },
    {
      name: "Sarah Chen",
      winRate: "92%",
      followers: "3.1K",
      profit: "721%",
      avatar: "/api/placeholder/100/100"
    },
    {
      name: "Michael Rodriguez",
      winRate: "87%",
      followers: "1.8K",
      profit: "428%",
      avatar: "/api/placeholder/100/100"
    }
  ];

  const features = [
    {
      icon: <Shield className="w-6 h-6" />,
      title: "Secure Trading",
      description: "Advanced encryption and security protocols to protect your assets"
    },
    {
      icon: <TrendingUp className="w-6 h-6" />,
      title: "Real-time Analytics",
      description: "Track performance and market movements in real-time"
    },
    {
      icon: <Globe2 className="w-6 h-6" />,
      title: "Global Access",
      description: "Trade and copy from anywhere, anytime"
    }
  ];

  return (
    <div className="pt-16 min-h-screen bg-gray-900 text-gray-100">
      <Header />
      <header className="container mx-auto px-4 py-16 md:py-24">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-6">
            Copy Trade with the Best
          </h1>
          <p className="text-xl md:text-2xl text-gray-400 mb-8">
            Follow top-performing traders and automatically copy their winning strategies
          </p>
          <button className="bg-yellow-600 hover:bg-yellow-700 text-white px-8 py-4 rounded-lg text-lg font-semibold transition-colors">
            Start Copy Trading
          </button>
        </div>
      </header>

      {/* Pro Traders Section */}
      <section className="bg-gray-800 py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
            Top Performing Traders
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {traders.map((trader, index) => (
              <div key={index} className="bg-gray-900 rounded-xl p-6 hover:shadow-xl transition-shadow">
                <div className="flex items-center mb-4">
                  <img
                    src={trader.avatar}
                    alt={trader.name}
                    className="w-12 h-12 rounded-full mr-4"
                  />
                  <div>
                    <h3 className="font-semibold text-lg">{trader.name}</h3>
                    <div className="flex items-center text-yellow-500">
                      <Star className="w-4 h-4 mr-1" />
                      <span>Top Trader</span>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div>
                    <p className="text-gray-400">Win Rate</p>
                    <p className="font-semibold">{trader.winRate}</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Followers</p>
                    <p className="font-semibold">{trader.followers}</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Total Profit</p>
                    <p className="font-semibold text-green-500">+{trader.profit}</p>
                  </div>
                </div>
                <button className="w-full mt-4 bg-yellow-600 hover:bg-yellow-700 text-white px-4 py-2 rounded-lg font-semibold transition-colors">
                  Copy Trader
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
            Why Choose Our Platform
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="bg-gray-800 rounded-xl p-6 hover:shadow-xl transition-shadow">
                <div className="text-yellow-500 mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Join Section */}
      <section className="bg-gray-800 py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              Ready to Start Copy Trading?
            </h2>
            <p className="text-xl text-gray-400 mb-8">
              Join thousands of successful traders and start copying their strategies today
            </p>
            <div className="flex flex-col md:flex-row gap-4 justify-center">
              <button className="bg-yellow-600 hover:bg-yellow-700 text-white px-4 py-2 rounded-lg text-sm font-semibold transition-colors">
                Create Account
              </button>
              <button className="bg-gray-700 hover:bg-gray-600 text-white px-8 py-4 rounded-lg text-lg font-semibold transition-colors">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CopyTradingWebsite;