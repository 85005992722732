import React, { useState } from 'react';
import Header from './header';

import { 
  Menu,
  X,
  Bitcoin, 
  TrendingUp, 
  Search,
  BookOpen,
  Tag,
  Clock,
  MessageCircle,
  Share2,
  ChevronDown,
  ChevronUp
} from 'lucide-react';

const CryptoBlog = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [expandedPosts, setExpandedPosts] = useState({});

  const togglePost = (postId) => {
    setExpandedPosts(prev => ({
      ...prev,
      [postId]: !prev[postId]
    }));
  };

  const blogPosts = [
    {
      id: 1,
      title: "Ethereum's Shift to Proof of Stake: One Year Later",
      category: "Technology",
      excerpt: "An in-depth analysis of Ethereum's performance and ecosystem changes since the historic merge to Proof of Stake...",
      fullContent: `An in-depth analysis of Ethereum's performance and ecosystem changes since the historic merge to Proof of Stake. The transition to Proof of Stake has been one of the most significant events in cryptocurrency history.

The impact on Ethereum's ecosystem has been profound, affecting everything from energy consumption to tokenomics. Here are the key developments we've observed:

1. Environmental Impact
- 99.95% reduction in energy consumption
- Significant decrease in carbon footprint
- Improved public perception and institutional adoption

2. Economic Changes
- Staking rewards have stabilized around 4-5% APR
- Over 20 million ETH currently staked
- Deflation periods during high network activity

3. Network Security
- Zero significant security breaches
- Increased decentralization through distributed validators
- Improved attack resistance metrics

4. Market Dynamics
- Changed supply and demand dynamics
- Impact on mining industry
- New staking derivatives market

The future outlook remains positive, with several key improvements planned:

- Proto-danksharding implementation
- Improved validator exits
- Enhanced staking efficiency

This transformation has positioned Ethereum strongly for future growth and adoption.`,
      author: "Alex Rivers",
      date: "Apr 15, 2024",
      readTime: "12 min read",
      tags: ["Ethereum", "PoS", "Sustainability"],
      comments: 45
    },
    {
      id: 2,
      title: "Zero Knowledge Proofs: The Future of Privacy",
      category: "Privacy",
      excerpt: "Understanding how ZK-proofs are revolutionizing blockchain privacy and scaling solutions...",
      fullContent: `Zero-knowledge proofs represent a breakthrough in blockchain privacy and scaling technology. This comprehensive analysis explores their current implementation and future potential.

Key Areas of Impact:

1. Privacy Solutions
- Transaction privacy in public blockchains
- Identity verification without data exposure
- Confidential smart contract execution

2. Scaling Benefits
- Reduced on-chain data requirements
- Improved transaction throughput
- Lower gas fees for users

3. Current Implementations
- ZK-Rollups on Ethereum
- Privacy-focused cryptocurrencies
- Corporate adoption cases

4. Technical Challenges
- Proof generation complexity
- Implementation difficulties
- Resource requirements

The technology continues to evolve with new breakthroughs in:
- Recursive proofs
- Multi-party computation
- Hardware acceleration

These developments are setting the stage for a new era of blockchain privacy and scalability.`,
      author: "Sarah Chen",
      date: "Apr 14, 2024",
      readTime: "8 min read",
      tags: ["Privacy", "ZK-Proofs", "Technology"],
      comments: 32
    },
    {
      id: 3,
      title: "Weekly Market Analysis: BTC, ETH, and Top Altcoins",
      category: "Market Analysis",
      excerpt: "Breaking down this week's market movements and key technical indicators...",
      fullContent: `This week's market analysis reveals significant movements across major cryptocurrencies and emerging trends in the broader market.

Bitcoin (BTC) Analysis:
- Support levels holding at key technical indicators
- Volume analysis shows increasing institutional interest
- Options market suggests bullish sentiment

Ethereum (ETH) Performance:
- Strong correlation with DeFi activity
- Impact of recent protocol upgrades
- Institutional adoption metrics

Top Altcoin Movements:
1. Layer 1 Competitors
- Performance metrics
- Development activity
- Ecosystem growth

2. DeFi Tokens
- TVL changes
- Yield comparisons
- Protocol updates

3. Gaming & Metaverse
- User activity metrics
- New project launches
- Market sentiment

Technical Outlook:
- Key resistance levels
- Volume profile analysis
- Market cycle indicators

The overall market structure suggests continued strength with key support levels holding.`,
      author: "Michael Torres",
      date: "Apr 13, 2024",
      readTime: "6 min read",
      tags: ["Market Analysis", "Trading", "Bitcoin"],
      comments: 28
    }
  ];

  return (
    <div className="pt-16 min-h-screen bg-gray-900 text-gray-100">
      <Header/>
      {/* Navigation */}
      <nav className="sticky top-0 z-40 bg-gray-900/95 backdrop-blur border-b border-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
         
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <a href="#" className="block px-3 py-2 text-yellow-500 font-medium">Latest</a>
              <a href="#" className="block px-3 py-2 text-gray-300 hover:text-yellow-500">Technology</a>
              <a href="#" className="block px-3 py-2 text-gray-300 hover:text-yellow-500">Markets</a>
              <a href="#" className="block px-3 py-2 text-gray-300 hover:text-yellow-500">DeFi</a>
            </div>
          </div>
        )}
      </nav>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid lg:grid-cols-12 gap-8">
          {/* Main Blog Feed */}
          <div className="lg:col-span-8 space-y-8">
            {blogPosts.map((post) => (
              <article 
                key={post.id} 
                className="bg-gray-800 rounded-xl overflow-hidden transition-transform hover:translate-y-[-4px] shadow-lg"
              >
                <div className="p-4 sm:p-6 lg:p-8">
                  {/* Article Header */}
                  <div className="flex flex-wrap items-center gap-2 mb-4">
                    <span className="bg-yellow-500 text-gray-900 text-xs font-medium px-2.5 py-0.5 rounded">
                      {post.category}
                    </span>
                    <div className="flex items-center text-gray-400 text-sm">
                      <Clock size={14} className="mr-1" />
                      {post.readTime}
                    </div>
                  </div>
                  
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-bold mb-3 hover:text-yellow-500 cursor-pointer transition-colors">
                    {post.title}
                  </h2>
                  
                  {/* Content Section */}
                  <div className="space-y-4">
                    <p className="text-gray-400 text-sm sm:text-base">
                      {expandedPosts[post.id] ? post.fullContent : post.excerpt}
                    </p>
                    
                    <button
                      onClick={() => togglePost(post.id)}
                      className="flex items-center text-yellow-500 hover:text-yellow-400 transition-colors text-sm font-medium"
                    >
                      {expandedPosts[post.id] ? (
                        <>
                          Show less
                          <ChevronUp size={16} className="ml-1" />
                        </>
                      ) : (
                        <>
                          Continue reading...
                          <ChevronDown size={16} className="ml-1" />
                        </>
                      )}
                    </button>
                  </div>

                  {/* Tags */}
                  <div className="flex flex-wrap gap-2 mt-4 mb-4">
                    {post.tags.map((tag, tagIndex) => (
                      <span 
                        key={tagIndex}
                        className="flex items-center text-xs text-gray-400 bg-gray-700 px-2 py-1 rounded hover:bg-gray-600 transition-colors cursor-pointer"
                      >
                        <Tag size={12} className="mr-1" />
                        {tag}
                      </span>
                    ))}
                  </div>

                  {/* Article Footer */}
                  <div className="flex flex-col sm:flex-row sm:items-center justify-between pt-4 border-t border-gray-700 gap-4">
                    <div className="flex items-center space-x-3">
                      <div className="w-8 h-8 rounded-full bg-gray-600"></div>
                      <div>
                        <p className="font-medium text-sm">{post.author}</p>
                        <p className="text-sm text-gray-400">{post.date}</p>
                      </div>
                    </div>
                    
                    <div className="flex items-center space-x-4 text-gray-400">
                      <button className="flex items-center space-x-1 hover:text-yellow-500 transition-colors">
                        <MessageCircle size={16} />
                        <span className="text-sm">{post.comments}</span>
                      </button>
                      <button className="hover:text-yellow-500 transition-colors">
                        <Share2 size={16} />
                      </button>
                    </div>
                  </div>
                </div>
              </article>
            ))}

            {/* Load More Button */}
            <div className="mt-8 text-center">
              <button className="w-full sm:w-auto bg-yellow-500 text-gray-900 px-6 py-2 rounded-lg font-medium hover:bg-yellow-400 transition-colors">
                Load More Articles
              </button>
            </div>
          </div>

          {/* Sidebar */}
          <aside className="lg:col-span-4 space-y-6">
            {/* Search Bar */}
            <div className="bg-gray-800 p-4 rounded-xl">
              <div className="relative">
                <input 
                  type="text" 
                  placeholder="Search articles..." 
                  className="w-full bg-gray-700 text-gray-100 rounded-lg pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                />
                <Search className="absolute left-3 top-2.5 text-gray-400" size={18} />
              </div>
            </div>

            {/* Popular Topics */}
            <div className="bg-gray-800 p-4 rounded-xl">
              <h3 className="text-lg font-bold mb-4 flex items-center">
                <TrendingUp size={20} className="mr-2 text-yellow-500" />
                Popular Topics
              </h3>
              <div className="flex flex-wrap gap-2">
                {['Bitcoin', 'Ethereum', 'DeFi', 'NFTs', 'Web3', 'Trading', 'Technology'].map((topic, index) => (
                  <span 
                    key={index}
                    className="bg-gray-700 text-gray-300 px-3 py-1 rounded-full text-sm cursor-pointer hover:bg-gray-600 transition-colors"
                  >
                    {topic}
                  </span>
                ))}
              </div>
            </div>

            {/* Reading List */}
            <div className="bg-gray-800 p-4 rounded-xl">
              <h3 className="text-lg font-bold mb-4 flex items-center">
                <BookOpen size={20} className="mr-2 text-yellow-500" />
                Reading List
              </h3>
              <div className="space-y-4">
                {[1, 2, 3].map((item) => (
                  <div key={item} className="flex items-start space-x-3">
                    <div className="w-16 h-12 bg-gray-700 rounded"></div>
                    <div>
                      <h4 className="font-medium text-sm hover:text-yellow-500 cursor-pointer">Top 10 DeFi Projects to Watch in 2024</h4>
                      <p className="text-gray-400 text-xs mt-1">5 min read</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </aside>
        </div>
      </main>
    </div>
  );
};

export default CryptoBlog;