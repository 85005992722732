import React, { useState, useEffect } from 'react';
import Header from './header';

import { 
  ArrowUpCircle, 
  ArrowDownCircle, 
  Loader2,
  RefreshCw,
  Bitcoin,
  DollarSign,
  Sparkles,
  TrendingUp,
  Wallet,
  LineChart
} from 'lucide-react';

const CryptoOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('all');

  const fetchOrders = async () => {
    setLoading(true);
    
    try {
      const response = await fetch('YOUR_API_ENDPOINT/orders');
      if (!response.ok) throw new Error('Failed to fetch orders');
      const data = await response.json();
      setOrders(data);
    } catch (err) {
      console.error('Error fetching orders:', err);
      setOrders([]); // Set empty orders instead of error state
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
    const intervalId = setInterval(fetchOrders, 30000);
    return () => clearInterval(intervalId);
  }, []);

  // Quick stats component
  const QuickStats = () => (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
      {[
        { icon: TrendingUp, label: 'Total Volume', value: '$124,532', color: 'text-blue-500' },
        { icon: Wallet, label: 'Active Orders', value: '23', color: 'text-green-500' },
        { icon: LineChart, label: 'Success Rate', value: '98.5%', color: 'text-yellow-500' }
      ].map((stat, index) => (
        <div key={index} className="bg-gray-800/50 rounded-lg p-4 flex items-center gap-4">
          <div className={`${stat.color} bg-opacity-10 rounded-lg p-2`}>
            <stat.icon className="h-5 w-5" />
          </div>
          <div>
            <p className="text-sm text-gray-400">{stat.label}</p>
            <p className="text-lg font-semibold">{stat.value}</p>
          </div>
        </div>
      ))}
    </div>
  );

  // Empty state component
  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center py-12 text-center">
      <div className="relative mb-6">
        <div className="relative">
          <Bitcoin className="h-12 w-12 text-gray-600 animate-pulse" />
          <Sparkles className="h-5 w-5 text-yellow-500 absolute -top-2 -right-2 animate-bounce" />
        </div>
      </div>
      <h2 className="text-xl font-semibold mb-2">No Orders Made</h2>
      <p className="text-gray-400 max-w-md mb-4 text-sm">
        Ready to start your crypto journey? Place your first order and watch your portfolio grow.
      </p>
      <button 
        onClick={fetchOrders}
        className="bg-yellow-600/90 hover:bg-yellow-600 text-white px-4 py-2 rounded-lg transition-colors duration-200 flex items-center gap-2 text-sm"
      >
        <RefreshCw className="h-4 w-4" />
        Refresh
      </button>
    </div>
  );

  return (
    <div className="pt-16 min-h-screen bg-gray-900 text-gray-100">
      <Header/>
      {/* Header */}
      <div className="bg-gray-800/50 border-b border-gray-700/50">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-xl font-semibold flex items-center gap-2">
                <Bitcoin className="h-5 w-5 text-yellow-500" />
                Orders you have made
              </h1>
              <p className="text-sm text-gray-400 mt-1">Real-time order tracking</p>
            </div>
            <button 
              onClick={fetchOrders}
              className="bg-yellow-600/90 hover:bg-yellow-600 p-1.5 rounded-lg transition-colors duration-200 group"
              title="Refresh orders"
            >
              <RefreshCw className="h-4 w-4 group-hover:rotate-180 transition-transform duration-300" />
            </button>
          </div>

          {/* Tabs */}
          <div className="flex space-x-4 mt-4">
            {['all', 'buying', 'selling'].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`px-3 py-1.5 text-sm rounded-lg transition-colors duration-200 ${
                  activeTab === tab 
                    ? 'bg-yellow-600/90 text-white' 
                    : 'text-gray-400 hover:text-white hover:bg-gray-700/50'
                }`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
        {!loading && orders.length > 0 && <QuickStats />}
        
        <div className="bg-gray-800/50 rounded-lg shadow-xl overflow-hidden backdrop-blur-sm">
          {loading ? (
            <div className="flex items-center justify-center py-12">
              <div className="flex flex-col items-center">
                <Loader2 className="h-8 w-8 text-yellow-600/90 animate-spin mb-3" />
                <p className="text-sm text-gray-400">Loading orders...</p>
              </div>
            </div>
          ) : orders.length === 0 ? (
            <EmptyState />
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-left bg-gray-700/30">
                    <th className="px-4 py-3 text-xs font-semibold">Type</th>
                    <th className="px-4 py-3 text-xs font-semibold">Crypto</th>
                    <th className="px-4 py-3 text-xs font-semibold">Amount</th>
                    <th className="px-4 py-3 text-xs font-semibold">Price</th>
                    <th className="px-4 py-3 text-xs font-semibold">Total</th>
                    <th className="px-4 py-3 text-xs font-semibold">Time</th>
                    <th className="px-4 py-3 text-xs font-semibold">Status</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-700/30">
                  {orders
                    .filter(order => activeTab === 'all' || order.type === activeTab.slice(0, -3))
                    .map((order) => (
                    <tr key={order.id} className="hover:bg-gray-700/20 transition-colors duration-150">
                      <td className="px-4 py-3">
                        <div className="flex items-center gap-1.5">
                          {order.type === 'buy' ? (
                            <ArrowUpCircle className="h-4 w-4 text-green-500" />
                          ) : (
                            <ArrowDownCircle className="h-4 w-4 text-red-500" />
                          )}
                          <span className={`text-sm ${order.type === 'buy' ? 'text-green-500' : 'text-red-500'}`}>
                            {order.type.charAt(0).toUpperCase() + order.type.slice(1)}
                          </span>
                        </div>
                      </td>
                      <td className="px-4 py-3 text-sm">{order.crypto}</td>
                      <td className="px-4 py-3 text-sm">{order.amount}</td>
                      <td className="px-4 py-3 text-sm">${order.price.toLocaleString()}</td>
                      <td className="px-4 py-3 text-sm">${order.total.toLocaleString()}</td>
                      <td className="px-4 py-3 text-sm">
                        {new Date(order.timestamp).toLocaleString()}
                      </td>
                      <td className="px-4 py-3">
                        <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium
                          ${order.status === 'completed' ? 'bg-green-500/10 text-green-400' : 
                            order.status === 'pending' ? 'bg-yellow-500/10 text-yellow-400' : 
                            'bg-red-500/10 text-red-400'}`}>
                          {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default CryptoOrders;