import React, { useState } from 'react';
import { 
  BookOpen, 
  Wallet, 
  TrendingUp, 
  Shield, 
  ChevronDown, 
  ChevronRight,
  ScrollText,
  GraduationCap,
  PlayCircle,
  BookOpenCheck
} from 'lucide-react';

const CryptoEducation = () => {
  const [activeSection, setActiveSection] = useState('basics');
  const [openModule, setOpenModule] = useState(null);

  const courses = {
    basics: [
      { title: 'What is Cryptocurrency?', duration: '15 min' },
      { title: 'Blockchain Fundamentals', duration: '20 min' },
      { title: 'Digital Wallets Explained', duration: '10 min' }
    ],
    trading: [
      { title: 'Understanding Market Basics', duration: '25 min' },
      { title: 'Reading Charts', duration: '30 min' },
      { title: 'Risk Management', duration: '20 min' }
    ],
    security: [
      { title: 'Keeping Your Crypto Safe', duration: '15 min' },
      { title: 'Common Scams to Avoid', duration: '20 min' },
      { title: 'Best Security Practices', duration: '25 min' }
    ]
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      {/* Hero Section */}
      <header className="px-4 py-16 text-center bg-gray-800">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl md:text-6xl font-bold mb-6">
            Master Cryptocurrency
          </h1>
          <p className="text-xl text-gray-300 mb-8">
            Your journey to understanding blockchain and crypto starts here
          </p>
          <button className="bg-yellow-700 hover:bg-yellow-600 text-white px-8 py-3 rounded-lg font-semibold transition-colors">
            Start Learning
          </button>
        </div>
      </header>

      {/* Features Grid */}
      <section className="px-4 py-16">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <FeatureCard 
            icon={<BookOpen className="w-8 h-8" />}
            title="Comprehensive Courses"
            description="From basics to advanced topics, learn at your own pace"
          />
          <FeatureCard 
            icon={<TrendingUp className="w-8 h-8" />}
            title="Market Analysis"
            description="Understand market trends and trading fundamentals"
          />
          <FeatureCard 
            icon={<Shield className="w-8 h-8" />}
            title="Security First"
            description="Learn best practices for keeping your assets safe"
          />
        </div>
      </section>

      {/* Course Sections */}
      <section className="px-4 py-16 bg-gray-800">
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-wrap gap-4 mb-8">
            <TabButton 
              active={activeSection === 'basics'}
              onClick={() => setActiveSection('basics')}
              icon={<GraduationCap />}
              text="Basics"
            />
            <TabButton 
              active={activeSection === 'trading'}
              onClick={() => setActiveSection('trading')}
              icon={<TrendingUp />}
              text="Trading"
            />
            <TabButton 
              active={activeSection === 'security'}
              onClick={() => setActiveSection('security')}
              icon={<Shield />}
              text="Security"
            />
          </div>

          <div className="space-y-4">
            {courses[activeSection].map((course, index) => (
              <CourseModule 
                key={index}
                title={course.title}
                duration={course.duration}
                isOpen={openModule === index}
                onClick={() => setOpenModule(openModule === index ? null : index)}
              />
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="px-4 py-16 text-center">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold mb-6">Ready to Start Your Journey?</h2>
          <p className="text-gray-300 mb-8">
            Join thousands of students learning cryptocurrency today
          </p>
          <button className="bg-yellow-700 hover:bg-yellow-600 text-white px-8 py-3 rounded-lg font-semibold transition-colors">
            Enroll Now
          </button>
        </div>
      </section>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-gray-800 p-6 rounded-xl">
    <div className="text-yellow-500 mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-400">{description}</p>
  </div>
);

const TabButton = ({ active, onClick, icon, text }) => (
  <button
    onClick={onClick}
    className={`flex items-center gap-2 px-6 py-3 rounded-lg font-semibold transition-colors ${
      active 
        ? 'bg-yellow-700 text-white' 
        : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
    }`}
  >
    {icon}
    {text}
  </button>
);

const CourseModule = ({ title, duration, isOpen, onClick }) => (
  <div className="bg-gray-700 rounded-lg overflow-hidden">
    <button
      onClick={onClick}
      className="w-full px-6 py-4 flex items-center justify-between hover:bg-gray-600 transition-colors"
    >
      <div className="flex items-center gap-3">
        <PlayCircle className="w-5 h-5 text-yellow-500" />
        <span className="font-medium">{title}</span>
      </div>
      <div className="flex items-center gap-4">
        <span className="text-sm text-gray-400">{duration}</span>
        {isOpen ? (
          <ChevronDown className="w-5 h-5" />
        ) : (
          <ChevronRight className="w-5 h-5" />
        )}
      </div>
    </button>
    {isOpen && (
      <div className="px-6 py-4 bg-gray-750 border-t border-gray-600">
        <p className="text-gray-300 mb-4">
          Learn about {title.toLowerCase()} through interactive lessons and practical examples.
        </p>
        <button className="flex items-center gap-2 text-yellow-500 hover:text-yellow-400 transition-colors">
          <BookOpenCheck className="w-5 h-5" />
          <span>Start Learning</span>
        </button>
      </div>
    )}
  </div>
);

export default CryptoEducation;